import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
  faYoutube,
} from "@fortawesome/fontawesome-free-brands";

function Footer() {
  let date = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="container white-text">
        <div className="row">
          <div className="col-md-6 row footer-row1">
            <img
              id="logo"
              className="w-50 h-50"
              src="/myImages/rytlogoWhite.webp"
              alt="logo"
            ></img>
            {/* <p className="col-md-7 align-left">Rainbow Youtheens is a family where young people are trained, moulded, nurtured,
              inspired, empowered and developed to serve GOD with passion and become the best
              in life</p> */}
          </div>
          <div className="col-md-6 row">
            {/* <hr /> */}
            <div className="col-md-6">
              <ul className="align-left">
                <Link to="/" className="text-link">
                  <li className="li-none">HOME</li>
                </Link>
                <Link to="/Blogpage" className="text-link">
                  <li className="li-none">BLOG</li>
                </Link>
                <Link to="/Academy" className="text-link">
                  <li className="li-none">AWARDS</li>
                </Link>
                <Link to="/Academy" className="text-link">
                  <li className="li-none">FOUNDATION ACADEMY</li>
                </Link>
              </ul>
            </div>
            <div className="col-md-6">
              <ul className="align-left">
                <Link to="/About" className="text-link">
                  <li className="li-none">ABOUT US</li>
                </Link>
                <Link to="/Contact" className="text-link">
                  <li className="li-none">CONTACT</li>
                </Link>
                <Link to="/Blogpage" className="text-link">
                  <li className="li-none">RESOURCES</li>
                </Link>
                <a
                  href="https://www.facebook.com/rainbowyoutheens"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white" }}
                >
                  <li className="d-inline fs-4 pe-3">
                    <FontAwesomeIcon icon={faFacebookSquare} />
                  </li>
                </a>
                <a
                  href="https://instagram.com/rainbowyoutheens?igshid=YmMyMTA2M2Y="
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white" }}
                >
                  <li className="d-inline fs-4 pe-3">
                    <FontAwesomeIcon icon={faInstagram} />
                  </li>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCQtog7Px4Nii0Q-zwcvS-6g"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "white" }}
                >
                  <li className="d-inline fs-4 pe-3">
                    <FontAwesomeIcon icon={faYoutube} />
                  </li>
                </a>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex-left">
          <p>Copyright &copy; {date} Rainbow Youtheens Ministry</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
