import React, { useState } from "react";
import "./styles-event.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faClock,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

const Event = () => {
  // Update date for the event
  var countDownDate = new Date("May 26, 2022 17:00:00").getTime();
  var expiredDate = new Date("May 27, 2022 20:00:00").getTime();
  const [time, setTime] = useState();
  const [left, setLeft] = useState("left");

  // Update the count down every 1 second
  var x = setInterval(function () {
    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Output the result in an element with id="demo"
    setTime(`${days}d : ${hours}h : ${minutes}m : ${seconds}s `);

    // If the count down is over, write some text
    if (now > expiredDate) {
      clearInterval(x);
      setTime("EXPIRED,");
      setLeft("");
    } else if (distance < 0) {
      clearInterval(x);
      setTime("CURRENTLY ONGOING");
      setLeft("");
    }
  }, 1000);

  return (
    <div>
      <div className="event-backgound">
        <div className="event-content">
          <div className="text-start text-white event-text">
            <h1 className="mb-3">
              <span className="display-3 fw-bold styled">The</span> Exponential
              Conference 2022
            </h1>
            <h4>Tagged: The Youth at Equilibrum</h4>
            <span className="d-block">
              <FontAwesomeIcon className="me-1" icon={faCalendarDay} /> 26th &
              27th MAY, 2022{" "}
            </span>
            <span className="d-block">
              <FontAwesomeIcon className="me-1" icon={faClock} /> 5PM WAT{" "}
            </span>
            <span className="d-block">
              <FontAwesomeIcon className="me-1" icon={faGlobe} /> Live on ZOOM
              APP
            </span>
            <span className="d-block">Meeting ID : 86493538682</span>
            <span className="d-block">Passcode : 431323</span>
          </div>
          <div className="event-img-div">
            <div id="demo" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/myImages/tec2022_1.png"
                    alt="event"
                    className="event-img shadow"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/myImages/miss_rakha_zabin.jpg"
                    alt="event"
                    className="event-img shadow"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/myImages/sir_favour.jpg"
                    alt="event"
                    className="event-img shadow"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/myImages/sir_kobabe.jpg"
                    alt="event"
                    className="event-img shadow"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/myImages/dr_obinna.jpg"
                    alt="event"
                    className="event-img shadow"
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#demo"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon"></span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#demo"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon"></span>
              </button>
            </div>
          </div>
        </div>
        <div className="time-left pb-3 pt-3 text-white">
          <span className="dis-3 time-styled">{time}</span>
          <span className="time-styled dis-5">{left}</span>
        </div>
      </div>
    </div>
  );
};

export default Event;
