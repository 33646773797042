import React, { useState, useEffect } from "react";
import EditorsProfile from "../EditorsProfile";
import "./AddBlog.css";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "../TextEditor.css";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../../context/UserAuthContext";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../../../firebase";

function AddBlog() {
  const { user } = useUserAuth();
  const [file, setFile] = useState();
  const [proceed, setProceed] = useState();
  const [loading, setLoading] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const [userInfo, setuserInfo] = useState({
    title: "",
    imageUrl: "",
    minRead: "",
    description: "",
  });
  const onChangeValue = (e) => {
    setuserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };
  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
  };
  const ondescription = (value) => {
    setuserInfo({ ...userInfo, description: value });
  };
  const [isError, setError] = useState(null);
  const uploadFiles = async (file) => {
    //
    if (!file) return;
    const storageRef = ref(storage, `/blog_images/${user.uid}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // const prog = Math.round(
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        // );
        // setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // url_data = downloadURL;
          // console.log("Image File available at", url_data);
          // console.log(userInfo);
          setProceed(downloadURL);
        });
      }
    );
  };
  const addDetails = async (event) => {
    setLoading("Currently loading, please be patient");
    setIsDisabled(true);
    try {
      event.preventDefault();
      event.persist();
      if (userInfo.description.length < 200) {
        setError("Required, Add description minimum length 200 characters");
        return;
      }
      uploadFiles(file);

      // axios.post(`http://localhost:8080/addArticle`, {
      //   title: userInfo.title,
      //   imageUrl: userInfo.imageUrl,
      //   minRead: userInfo.minRead,
      //   description: userInfo.description,
      // })
      // .then(res => {
      //   if(res.data.success === true){
      //     navigate.push('/');
      //   }
      // })
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    setuserInfo({
      ...userInfo,
      imageUrl: proceed,
    });
    // console.log(userInfo.imageUrl);

  }, [proceed]);

  useEffect(() => {
    const addMainDoc = async () => {
      if (userInfo.imageUrl === undefined || userInfo.imageUrl === "") {
        return
      } else {
        try {
          // done: post the information to cloud firestore
          await addDoc(collection(db, `blogger: ${user.uid}`), {
            email: user.email,
            title: userInfo.title,
            description: userInfo.description,
            imageUrl: userInfo.imageUrl,
            minRead: userInfo.minRead,
            createdAt: serverTimestamp(),
            name: user.displayName,
          }).then(() => {
            navigate("/editorspage");
          });
        } catch (err) {
          alert(err);
        }
      } 
    };
    addMainDoc();
  }, [userInfo.imageUrl])

  return (
    <div>
      <div className="wrapper">
        <EditorsProfile text="Cancel" url_path="/editorspage"/>
        <div className="two shadow" style={{padding: "13px 10px 0 10px", minHeight: "750px"}}>
          <h3 className="text-white text-start mb-4 ms-5">Add New Blog</h3>
          <form onSubmit={addDetails} className="text-start">
            <div className="my-2">
              <label htmlFor="title" className="text-white me-2">
                Title:<span className="required"> * </span>
              </label>
              <input
                type="text"
                name="title"
                value={userInfo.title}
                onChange={onChangeValue}
                id="title"
                className="rounded-3 border-0 py-1 px-2"
                required
              />
            </div>
            <div className="my-2">
              <label htmlFor="minutes-read" className="text-white me-2">
                How many minutes read?<span className="required"> * </span>
              </label>
              <input
                type="number"
                name="minRead"
                value={userInfo.minRead}
                onChange={onChangeValue}
                id="minutes-read"
                className="rounded-3 border-0 py-1 px-2"
                style={{ width: "60px"}}
                required
              />
            </div>
            <div className="my-2">
              <label htmlFor="add-image" className="text-white me-2">
                Add Image:<span className="required"> * </span>
              </label>
              <input
                type="file"
                name="imageUrl"
                onChange={handleFileInput}
                id="imageUrl"
                className="rounded-3 border-0"
                accept=".png, .jpeg, .jpg"
                required
              />
            </div>
            <div className="my-2">
              <div className="clearfix"></div>
              <div className="form-group col-md-12 editor">
                <label className="font-weight-bold text-white">
                  Description:<span className="required"> * </span>
                </label>
                <div className="bg-white rounded-3">
                  <EditorToolbar toolbarId={"t1"} />
                  <ReactQuill
                    theme="snow"
                    value={userInfo.description}
                    onChange={ondescription}
                    placeholder={"Write something awesome..."}
                    modules={modules("t1")}
                    formats={formats}
                    className="react-quill__height"
                  />
                </div>
              </div>
            </div>
            {isError !== null && (
              <div className="errors text-warning"> {isError} </div>
            )}
            <div className="form-group col-sm-12 text-right">
              <button type="submit" className="btn btn__theme text-white bg-warning shadow" disabled={isDisabled}>
                Submit
              </button>
              {loading}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddBlog;
