import React from "react";
import BoysConnect from "../BoysConnect";
import Conference from "../Conference";
import Footer from "../Footer";
import GirlsHangout from "../GirlsHangout";
import Outreach from "../Outreach";
import Navbar from "../Subcomponents/Navbar";
import "./style.css";

function MainGallery() {
  return (
    <div className="grey-col">
      <Navbar />
      <div className="maingallery">
        <Outreach />
        <BoysConnect />
        <GirlsHangout />
        <Conference />
      </div>
      <Footer />
    </div>
  );
}

export default MainGallery;
