import React from "react";
import "./App.css";
import Router from './components/Router'
import { UserAuthContextProvider } from "./context/UserAuthContext";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <UserAuthContextProvider>
      <ScrollToTop />
      <div className="App">
        <Router />
      </div>
    </UserAuthContextProvider>
  );
}

export default App;
