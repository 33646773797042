import React from 'react';
import "./styles.css";

const EmptyList = () => {
  return (
    <div className='emptyList-wrap'>
        <img src="" alt="" />
    </div>
  )
}

export default EmptyList