import React, { useState } from "react";
import Navbar from "../../components/Subcomponents/Navbar";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";

function PitchEvent() {
  return (
    <div>
      <Navbar />

      <div id="service">
        <div
          className="container"
          style={{ paddingTop: "70px", backgroundColor: "#040453" }}
        >
          <div className="row pb-5">
            <div className="col-md-6 text-start mt-4">
              <h2>
                🌈🌟 <b className="red-text">Join</b> the Exciting Youth
                Competition by{" "}
                <b className="yel-text">Rainbow Youtheens Ministry!</b> 🌟🌈
              </h2>
              <p className="my-4 fw-bold">
                Calling all talented young minds aged 15-25!!!
              </p>
              <p>
                Are you passionate about IT, Business/ Entrepreneurship, or
                Creativity? Do you have an existing business? We’ve got an
                exciting opportunity just for you!{" "}
              </p>
              <p>
                Get ready for an electrifying showcase of talent and skill in
                the most awaited competition of the year.
              </p>
              <p>
                🔹 <b>Theme:</b> PITCH UP: SKILL HIGHER.
                <br />
                🔹 <b>Categories for Entry:</b> IT, General Business, and
                Creativity.
              </p>
              <p className=" my-4">
              📅 Entry Period: <b className="red-text">10 August</b><b> - </b>
              <b className="red-text">10 September, 2023</b>.
            </p>
              <span className="flex-left">
                <a
                  style={{ textDecoration: "none", fontSize: "18px" }}
                  className="btn-1 button-bg text-white"
                  href="#register-pitch"
                >
                  <b>Register Now</b>
                </a>
              </span>
            </div>
            <div className="col-md-6">
              <img
                src="myImages/Pitch-Up-Flyer.jpg"
                alt="poster"
                style={{ borderRadius: "15px" }}
                className="shadow w-75 mt-3"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-3">
          <div className="col-md-5 text-start mt-5 mx-auto">
            <p>
              🌟 What's in it for you? <br />
              <b className="yel-text">₦50,000</b> cash price for the winner in
              each category.
            </p>
            <p>
              🏆 Showcase Your Talent. Demonstrate your expertise in IT, impress
              us with your business acumen, or captivate the audience with your creative prowess. 🌐💼🗣️📝
            </p>
            <p>
              <b className="red-text">*</b>{" "}
              <b>For Youths Between Ages 15 - 25</b>
            </p>
          </div>
          <div className="col-md-6 mt-5">
            <img
              src="myImages/male-athlete-holding-medal.jpg"
              alt="poster"
              style={{ borderRadius: "15px", width: "65%" }}
              className="shadow"
            />
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-6 mt-3">
            <img
              src="myImages/do-something-great.jpg"
              alt="poster"
              style={{ borderRadius: "15px", width: "65%" }}
              className="shadow mt-3"
            />
          </div>
          <div className="col-md-5 text-start mt-5">
            <p>
              🌈 Unleash Your Potential: This competition is your chance to
              unleash your true potential and shine brightly among your peers.
              🌟
            </p>
            <p id="register-pitch">
              💡 Expert Judging Panel: Our panel of esteemed judges comprises
              industry experts and experienced speakers who will provide
              valuable feedback and support to help you grow. 🤝
            </p>
          </div>
        </div>

        <div className="row text-start mx-auto">
          <h3 className="text-center mb-3">
            <b>HOW TO PARTICIPATE?</b>
          </h3>
          <div className="col-sm-12 col-md-9 col-lg-9 mx-auto">
            <ol>
              <li>
                Choose Your Category: Decide whether you want to showcase your
                IT skills, business acumen, or your creative prowess.
              </li>
              <li>
                Create Your Pitch: Prepare an innovative project, business plan
                in a <b>1 - 3 mins</b> video.
              </li>
              <li>
                Submit Your Entry: Send in your submission to us{" "}
                <b className="yel-text">
                  <a
                    href="https://forms.gle/8L9BvM7rXgTNSxhc7"
                    rel="noreferrer"
                  >
                    here{" "}
                  </a>
                </b>
                before the deadline. Don't miss the opportunity to be a part of
                this thrilling competition!
              </li>
            </ol>
            <h5 className="text-center my-4">
              Entry opens <b className="red-text">August 10</b> & closes{" "}
              <b className="red-text">September 10, 2023</b>.
            </h5>
            <p>
              🎉 Don't miss out on this amazing chance to elevate your skills
              and win fantastic prizes! Join the Rainbow Youtheens Ministry's
              Pitch up:skill higher competition today! 🎉
            </p>
            <p>
              📧 For any queries, contact us via our Facebook: <a href="https://www.facebook.com/rainbowyoutheens/" rel="noreferrer" target='_blank'>RAINBOW Youtheens</a> and Instagram: <a href="https://www.instagram.com/rainbowyoutheens/?igshid=YmMyMTA2M2Y%3D" rel="noreferrer" target='_blank'>@rainbowyoutheens</a> or
              email us at <a href="mailto:rainbowyoutheens@gmail.com">rainbowyoutheens@gmail.com</a>
. Go to{" "}
              <b>
                <Link to="/contact">Contact Us</Link>
              </b>{" "}
              for more details.
            </p>
            <p>
              🌟 Unlock your potential, rise to the challenge, and let your
              skills soar to new heights! See you at the competition!
            </p>
            <span className="flex-left mb-4">
              <a
                href="https://forms.gle/8L9BvM7rXgTNSxhc7"
                rel="noreferrer"
                style={{ textDecoration: "none", fontSize: "18px" }}
                className="btn-1 button-bg text-white"
              >
                <b>Register Here</b>
              </a>
            </span>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default PitchEvent;
