import React, { useState } from "react";
import { db } from "../../../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useUserAuth } from "../../../context/UserAuthContext";
import Comments from "../Comments";
import './CommentForm.css'

function CommentForm({ id }) {
  const [comment, setComment] = useState("");
  const { user } = useUserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    document.getElementById("commentform-textarea").value = "";
    

    try {
      // console.log(comment)
      await addDoc(collection(db, `comments: ${id}`), {
        email: user.email,
        comment: comment,
        createdAt: serverTimestamp(),
        edited: false,
        name: user.displayName,
        reply: []
      });
    } catch (err) {
      alert(err);
    }
  };

  return (
    <div className="blog-wrap text-start ps-2 mb-4">
      <h5 className="">Add a Comment</h5>
      <form onSubmit={handleSubmit}>
        <textarea
          className="px-2 py-2 border-0 comment__form__textarea"
          style={{ resize: "none", width: '97.5%' }}
          name="comment"
          id="commentform-textarea"
          onChange={(e) => setComment(e.target.value)}
          placeholder="Enter a comment here..."
          rows="4"
        />
        <br />
        <button
          className="px-3 py-2 text-white border-0 shadow bg-warning comment__form__submit"
          type="Submit"
        >
          Submit
        </button>
      </form>
      <Comments id={id} />
    </div>
  );
}

export default CommentForm;
