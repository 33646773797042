import React from "react";
import "./style.css";
import { LightBox } from "react-lightbox-pack";
import "react-lightbox-pack/dist/index.css";
import dataArr5 from "../../config/dataArr5.json";

function Outreach() {
  const [toggle, setToggle] = React.useState(false);
  const [sIndex, setSIndex] = React.useState(0);
  const lightBoxHandler = (state, sIndex) => {
    setToggle(state);
    setSIndex(sIndex);
  };

  return (
    <div className="container-g">
      <h2 className="my-3">ANTI-CULTISM OUTREACH</h2>
      <div className="video-responsive">
        <iframe
          width="853"
          height="480"
          src="https://www.youtube.com/embed/MbtvdAJ9OY8"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
      <div id="outreach" className="gallery-2">
        {dataArr5.map((item, index) => (
          <figure className={`gallery__item ${item.class}`} key={item.id}>
            <img
              key={item.id}
              src={item.image}
              alt={item.title}
              className="gallery__img"
              onClick={() => {
                lightBoxHandler(true, index);
              }}
            />
          </figure>
        ))}
        <LightBox
          state={toggle}
          event={lightBoxHandler}
          data={dataArr5}
          imageWidth="60vw"
          imageHeight="70vh"
          thumbnailHeight={50}
          thumbnailWidth={50}
          setImageIndex={setSIndex}
          imageIndex={sIndex}
        />
      </div>
    </div>
  );
}

export default Outreach;
