import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faWhatsapp, faFacebookSquare } from '@fortawesome/fontawesome-free-brands';
import { RWebShare } from "react-web-share";

function Icons({ link, bgColor }) {
  const shareHandler = (e) => {
    if (e.target.id === "facebook") {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${link}`, "_blank")
    } else if (e.target.id === "whatsapp") {
      window.open(`whatsapp://send?text=${link}`, "_blank")
    } else if (e.target.id === "twitter")
      window.open(`https://twitter.com/intent/tweet?url=${link}`, "_blank")
  }

  return (
    <div>
      <div id="icons" className='mb-1 mt-2'>
        <RWebShare
          data={{
            text: "Share Rainbow Youtheens Website",
            url: link,
            title: "Rainbow Youtheens",
          }}
          onClick={() => console.log("shared successfully!")}
        >
          <button className="border-0 blue-text" style={{ backgroundColor: bgColor }}>
            <span className='me-1 fw-bold'>Share</span>
            <FontAwesomeIcon style={{ pointerEvents: "none" }} className='font-awesome' icon={faShare} />
          </button>
        </RWebShare>
      </div>
    </div>
  )
}

export default Icons