import React from 'react';
import './style.css'

import { LightBox } from "react-lightbox-pack";
import "react-lightbox-pack/dist/index.css";
import dataArr2 from '../../../config/dataArr2.json'

function BoysConnect20() {
    const [toggle, setToggle] = React.useState(false);
    const [sIndex, setSIndex] = React.useState(0);
    const lightBoxHandler = (state, sIndex) => {
        setToggle(state);
        setSIndex(sIndex);
    };

    return (
        <div className="container-g">
            <h2 className='my-3' >BOYS CONNECT 2020</h2>
            <div id='boysconnect20' className="gallery-1">
                {dataArr2.map((item, index) => (
                    <figure className={`gallery__item ${item.class}`} key={item.id}>
                        <img
                            key={item.id}
                            src={item.image}
                            alt={item.title}
                            className="gallery__img"
                            onClick={() => {
                                lightBoxHandler(true, index);
                            }}
                        />
                    </figure>
                ))}
                <LightBox
                    state={toggle}
                    event={lightBoxHandler}
                    data={dataArr2}
                    imageWidth="60vw"
                    imageHeight="70vh"
                    thumbnailHeight={50}
                    thumbnailWidth={50}
                    setImageIndex={setSIndex}
                    imageIndex={sIndex}
                />
            </div>
        </div>
    )
}

export default BoysConnect20