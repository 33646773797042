import React, { useState } from 'react';
import EmptyList from '../../components/common/EmptyList';
import Footer from '../../components/Footer';
import BlogList from '../../components/BlogContent/BlogList';
import Header from '../../components/BlogContent/Header'
import Navbar from '../../components/Subcomponents/Navbar';
import { blogList } from '../../config/data'
// import SearchBar from '../../Component/BlogContent/SearchBar';



const Blogpage = () => {
  const [blogs, setBlogs] = useState(blogList);
  const rev_blogs = blogs.slice().reverse();

  return (    
    <div className="grey-col">
      <Navbar />
      <div><Header /></div>
      <div className='container-3'>
        {!blogs.length ? <EmptyList /> : <BlogList blogs={rev_blogs} />}

      </div>
      <Footer />
    </div>
    
  );
};


export default Blogpage