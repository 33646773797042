import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useUserAuth } from "../../context/UserAuthContext";
import EditBlog from "./EditBlog";

function Edit(prop) {
  let id = useParams();
  const location = useLocation();
  const uid = location.state;
  // const location = useLocation();
  // const uid = location.state;
  // console.log(uid);
  // const { user } = useUserAuth();
  // console.log(user);
  console.log(uid);
  // const [doesExist, setExist] = useState(false);
  const [proceed, setProceed] = useState();
  const [blogInfo, setBlogInfo] = useState();

  useEffect(() => {
    console.log("Working FIne");
    const viewPostID = async () => {
      const docRef = doc(db, `blogger: ${uid}`, id.blogID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // setExist(true);
        // console.log(doesExist)
        console.log("Document data:", docSnap.data());
        setProceed({
          id: docSnap.id,
          data: docSnap.data(),
          date: docSnap.data().createdAt.toDate(),
        });
        console.log(blogInfo);
      } else {
        // doc.data() will be undefined in this case
        // setExist(false);
        console.log("No such document!");
      }
    };

    viewPostID();
  }, []);

  useEffect(() => {
    setBlogInfo(proceed);
    console.log(proceed);
  }, [proceed]);

  if (proceed === undefined) {
    return null;
  } else {
    return (
      <>
        <EditBlog
          title={proceed.data.title}
          minRead={proceed.data.minRead}
          description={proceed.data.description}
          imageUrl={proceed.data.imageUrl}
          editblogID={id.blogID}

        />
      </>
    );
  }
}

export default Edit;
