import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./EditorsProfile.css";
import { useUserAuth } from "../../../context/UserAuthContext";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../firebase";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function EditorsProfile({ text, url_path }) {
  const { user, updatePhotoURL } = useUserAuth();
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState("");
  const [alert, setAlert] = useState("");
  const [image, setImage] = useState();
  const [isShow, setIsShow] = useState(true);
  const [mprofile, setMProfile] = useState("");
  let navigate = useNavigate();

  // done: Function that takes in url path of the button clicked for specific pages
  const handleUrlBtn = () => {
    navigate(url_path);
  };

  const handleClose = () => {
    setShow(false);
    if (alert === "Choose a file less than 3MB.") {
      document.getElementById("file-input").click();
    }
  };
  const handleShow = () => setShow(true);

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    const size = Math.round(file.size / 1024);
    if (size > 3072) {
      setAlert("File is bigger than 3MB.");
      handleShow();
    } else {
      uploadFiles(file);
    }
  };

  const formHandler = (e) => {
    e.preventDefault();

    setAlert("Choose a file less than 3MB.");
    handleShow();
  };

  const uploadFiles = async (file) => {
    //
    if (!file) return;
    const storageRef = ref(storage, `/profilepic/${user.uid}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // const prog = Math.round(
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        // );
        // setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          updatePhotoURL(downloadURL).then(() => {
            console.log("New url", user.photoURL);
            setUrl(user.photoURL);
          });
        });
      }
    );
  };

  // done: To open the profile when clicked in mobile view.
  const toggleProfile = () => {
    if (isShow === true) {
      setMProfile("profile-show");
    } else {
      setMProfile("");
    }
    setIsShow(!isShow);
  };

  useEffect(() => {
    // console.log(user.photoURL);
    setImage(false);
    setUrl(user.photoURL);
  }, [user.photoURL]);

  return (
    <div className="one">
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body>{alert}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="blog__profile-pic">
        {image ? (
          <button className="border border-0 bg-transparent btn__open-profile">
            <img
              className="w-75 rounded-circle mx-auto"
              src="/myImages/profile_pic.png"
              alt="Profile Pic"
            />
          </button>
        ) : (
          <button
            className="border border-0 bg-transparent btn__open-profile"
            onClick={toggleProfile}
          >
            <img
              className="w-75 rounded-circle mx-auto"
              src={url}
              // onError={(e)=>{e.target.onerror = null; e.target.src={url}}}
              alt="Profile Pic"
            />
          </button>
        )}
        <br />
        <form className="blog__profile-form" onSubmit={formHandler}>
          <input
            type="file"
            className="d-none"
            onChange={handleFileInput}
            name=""
            id="file-input"
            accept=".png, .jpeg, .jpg"
          />
          <button
            type="submit"
            className="rounded-circle border-0 bg-transparent change-profile-pic"
          >
            <img
              className=""
              src="myImages/penCircle.svg"
              alt="Change Profile Pic"
            />
          </button>
        </form>
      </div>
      <div className="blog__info">
        <h4 className="mb-4 blog__username">{user.displayName}</h4>
        <button
          onClick={handleUrlBtn}
          className="border border-0 curved-btn px-3 py-2 shadow bg-warning text-white"
        >
          {text}
        </button>
        <br />
        <button
          onClick={() => navigate("/")}
          className="border border-0 curved-btn px-3 py-2 my-3 bg-red shadow text-white"
        >
          Log Out
        </button>
      </div>

      {/* This code below shows or hides the profile in mobile devices */}
      <div className={`one-m ${mprofile}`}>
        <button
          className="border-0 bg-transparent ml-auto"
          onClick={toggleProfile}
        >
          <FontAwesomeIcon icon={faClose} style={{ fontSize: "30px" }} />
        </button>
        <div className="blog__profile-pic-m">
          {image ? (
            <button className="border border-0 bg-transparent btn__open-profile-m">
              <img
                className="w-75 rounded-circle mx-auto"
                src="/myImages/profile_pic.png"
                alt="Profile Pic"
              />
            </button>
          ) : (
            <button className="border border-0 bg-transparent btn__open-profile-m">
              <img
                className="w-75 rounded-circle mx-auto"
                src={url}
                // onError={(e)=>{e.target.onerror = null; e.target.src={url}}}
                alt="Profile Pic"
              />
            </button>
          )}
          <br />
          <form className="blog__profile-form-m" onSubmit={formHandler}>
            <input
              type="file"
              className="d-none"
              onChange={handleFileInput}
              name=""
              id="file-input"
              accept=".png, .jpeg, .jpg"
            />
            <button
              type="submit"
              className="rounded-circle border-0 bg-transparent change-profile-pic-m"
            >
              <img
                className=""
                src="myImages/penCircle.svg"
                alt="Change Profile Pic"
              />
            </button>
          </form>
        </div>
        <div className="blog__info-m mb-3">
          <h4 className="mb-4 blog__username-m">{user.displayName}</h4>
          <button onClick={() => navigate(url_path)} className="border border-0 curved-btn px-3 py-2 shadow bg-warning text-white">
            {text}
          </button>
          <br />
          <button onClick={() => navigate("/")} className="border border-0 curved-btn px-3 py-2 my-3 bg-red shadow text-white">
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditorsProfile;
