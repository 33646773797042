import React from 'react';
import './style.css'
import BoysConnect19 from './BoysConnect19';
import BoysConnect20 from './BoysConnect20';

function BoysConnect() {
    return (
        <div>
            <BoysConnect20 />
            <BoysConnect19 />            
        </div>
    )
}

export default BoysConnect