import React, { useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import "./style-head.css";
import Donate from "../Subcomponents/Donate";
import Alert from 'react-bootstrap/Alert';

function Header() {
  const [displayDonate, setDisplayDonate] = useState("none");


  const handleWYTP = async (e) => {
    e.preventDefault();
    const documentId = "a1f72a706nBJ9A64WKiV"; // Replace with the document ID you have permission to access
    const collectionName = "WYTP-Link"; // Replace with the collection name you have permission to access
  
    try {
      const linkRef = doc(db, collectionName, documentId);
      const linkSnap = await getDoc(linkRef);
  
      if (linkSnap.exists()) {
        window.open(`${linkSnap.data().link}`, "_blank");
      }
    } catch (err) {
      // if(err.message === "Missing or insufficient permissions."){
      //   return <Alert variant={'primary'}>
      //     Login to watch When Youth And Teens Pray Live!
      //   </Alert>
      // }
      console.log(err.message);
    }
  };
  
  const handleDonate = () => {
    setDisplayDonate("block");
  };

  const handleDonateClose = (data) => {
    setDisplayDonate(data);
  }

  return (
    <div className="">
      <div
        style={{
          paddingTop: "0rem",
          height: "100vh",
          width: "100vw !important",
          margin: "auto",
        }}
        id="carouselExampleFade"
        className="carousel slide carousel-fade overflow-hidden carousel-header-bg"
        data-bs-ride="carousel"
      >
        <div
          style={{
            paddingTop: "0rem",
            height: "100vh",
            width: "100vw !important",
            margin: "auto",
            minHeight: "100vh",
          }}
          className="carousel-inner"
        >
          <div
            style={{ paddingTop: "0rem", height: "100vh", margin: "auto" }}
            className="carousel-item active bg-img-1"
            data-bs-interval="5000"
          >
            {/* <img src="myImages/boys_20_5.jpg" style={{maxHeight: '100vh'}} className="d-block w-100 header-carousel-img" alt="..." /> */}
          </div>
          <div
            style={{ paddingTop: "0rem", height: "100vh", margin: "auto" }}
            className="carousel-item bg-img-2"
            data-bs-interval="5000"
          >
            {/* <img src="myImages/boys_20_11.jpg" style={{maxHeight: '100vh'}} className="d-block w-100" alt="..." /> */}
          </div>
          <div
            style={{ paddingTop: "0rem", height: "100vh", margin: "auto" }}
            className="carousel-item bg-img-3"
            data-bs-interval="5000"
          >
            {/* <img src="myImages/boys_20_16.jpg" className="d-block w-100" alt="..." /> */}
          </div>
          <div
            style={{ paddingTop: "0rem", height: "100vh", margin: "auto" }}
            className="carousel-item bg-img-4"
            data-bs-interval="5000"
          >
            {/* <img src="myImages/boys_20_16.jpg" className="d-block w-100" alt="..." /> */}
          </div>
        </div>
      </div>
      <div className="home-header-bg d-flex justify-content-center">
        <div>
          <div className="text text-white">
            <h1 className="header-text">
              <strong>
                "WE ARE <span style={{ color: "#fac226" }}>RAINBOW</span>"
              </strong>
            </h1>
            <p className="paragraph">Bulding a GODly generation...</p>
            {/* <Link to="/donate" target="_blank" className="white-text me-3"> */}
            <button
              onClick={handleDonate}
              className="button yel-col mb-btn-size me-3"
            >
              <strong>DONATE NOW</strong>
            </button>
            {<Donate displayDonate={displayDonate} sendDatatoParent={handleDonateClose}/>}
            <Link to="/pitch-event" className="white-text">
              <button className="button mb-btn-size">
                <strong>UPCOMING EVENT</strong>
              </button>
            </Link>
          </div>
          <div className="space-text text-white">
            <p>
              <span className="rainbow">R</span>ighteous{" "}
              <span className="rainbow">A</span>cceptable{" "}
              <span className="rainbow">I</span>nfluential{" "}
              <span className="rainbow">N</span>oble{" "}
              <span className="rainbow">B</span>old{" "}
              <span className="rainbow">O</span>utstanding{" "}
              <span className="rainbow">W</span>orthy
            </p>
          </div>
        </div>
      </div>
      {/* <div className="wytp-z-index">
        <button className="wytp-button border-0" onClick={handleWYTP}>
        <strong>WHEN YOUTH AND TEENS PRAY</strong>
        </button> */}
        {/* <a
          className="wytp-button border-0"
          href="https://www.facebook.com/rainbowyoutheens"
          target="_blank"
          rel="noreferrer"
        >
          <strong>WHEN YOUTH AND TEENS PRAY</strong>
        </a> */}
      {/* </div> */}
    </div>
  );
}

export default Header;
