import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "react-bootstrap";
import { useUserAuth } from "../../context/UserAuthContext";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { signUp, googleSignIn } = useUserAuth();
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (password !== confirmPassword) {
      return setError("Passwords do not match.");
    }

    try {
      await signUp(email, password, name);
      navigate(-2);
    } catch (err) {
      if (
        err.message ===
        "Firebase: Password should be at least 6 characters (auth/weak-password)."
      ) {
        return setError(
          "Password should be at least 6 characters (Weak Password)."
        );
      }
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
    } catch (error) {
      // console.log(error.message);
    }
    navigate(-2);
  };

  const handlecloseButton = async (e) => {
    e.preventDefault();
    navigate(-2);
  };

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100 login__main">
      <div className="login__box rounded-3">
        <div className="" style={{ textAlign: "right" }}>
          <Link to="" onClick={handlecloseButton}>
            <FontAwesomeIcon
              icon={faClose}
              style={{ fontSize: "26px", color: "black" }}
            />
          </Link>
        </div>
        <h2 className="pb-4">Sign Up</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <form action="" onSubmit={handleSubmit}>
          <div className="login__input__div text-start">
            <label className="text-start" htmlFor="email-address">
              Username
            </label>
            <br />
            <input
              className="my-2"
              type="text"
              onChange={(e) => setName(e.target.value)}
              placeholder="Full Name"
              name="name"
              required
            />
          </div>
          <div className="login__input__div text-start">
            <label className="text-start" htmlFor="email-address">
              Email Address
            </label>
            <br />
            <input
              className="my-2"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email Address"
              name="email"
            />
          </div>
          <div className="login__input__div text-start">
            <label className="" htmlFor="password1">
              Password
            </label>
            <br />
            <input
              className="my-2"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              name="password1"
              id="password1"
            />
          </div>
          <div className="login__input__div text-start">
            <label className="" htmlFor="password2">
              Confirm Password
            </label>
            <br />
            <input
              className="my-2"
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              name="password2"
              id="password2"
            />
          </div>
          <button
            className="login__btn login__btn__email rounded-pill my-2 px-1"
            type="submit"
          >
            <strong>Sign Up</strong>
          </button>
          <div className="d-flex">
            <hr />
            <span className="px-2 fw-bold">OR</span>
            <hr />
          </div>
          <button
            className="login__btn login__google rounded-pill mb-2 mt-1 px-5"
            onClick={handleGoogleSignIn}
          >
            <img src="myImages/Google__G__Logo.png" alt="Google logo" height="24" width="24" />
            <strong className="ps-3">Log in with Google</strong>
          </button>
          {/* <br />
          <button className="login__btn login__facebook rounded-pill my-2 px-5">
            <FontAwesomeIcon
              icon="fa-brands fa-facebook"
              className="fa-facebook"
            />
            <strong className="ps-3">Sign in with Facebooke</strong>
          </button> */}
        </form>
        <div className="box mt-3 text-center">
          Already have an account?{" "}
          <Link style={{ textDecoration: "none" }} to="/login">
            Log In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
