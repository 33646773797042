import React, { useState } from 'react';
import Navbar from './Subcomponents/Navbar';
import Footer from './Footer';

function Contact() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    window.open(`mailto:rainbowyoutheens@gmail.com?subject=${subject}, from ${email}&body=Name: ${firstName} ${lastName}<br>${body}`)
  }

  return <div id='contact-us-id'>
    <Navbar />
    <div id="contact-us" className="d-flex align-items-center justify-content-center grey-col segoe-ui just-space pt-2 pb-2">
      <div className="">
        <h3>Contact Us</h3>
        <p>
          <a className="link-col" href="tel:+2349090853499">+234-909-085-3499<br /></a>
          rainbowyoutheens@gmail.com</p>
        <div className="red-line-contact mb-3 mt-4"></div>
        <h3>Address:</h3>
        <p className='px-2'>Queen Mary Drive, St Catherine, <br />Niagara-on-the-Lake, ON, Canada, Ontario.
        </p>

      </div>
      <div className="mt-2 mb-5">
        <form onSubmit={handleSubmit}>
          <input className="mt-3 border-0 px-2 py-2 me-3 rounded-3 shadow name-width" onChange={(e) => setFirstName(e.target.value)} placeholder="First name" type="text" />
          <input className="mt-3 border-0 px-2 py-2 rounded-3 shadow name-width" onChange={(e) => setLastName(e.target.value)} placeholder="Last name" type="text" /> <br />
          <input className="mt-3 border-0 px-2 py-2 rounded-3 shadow add-length" onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" type="email" /> <br />
          <input className="mt-3 border-0 px-2 py-2 rounded-3 shadow add-length" onChange={(e) => setSubject(e.target.value)} placeholder="Subject" type="text" /> <br />
          <textarea className="mt-3 border-0 px-2 rounded-3 py-1 p shadow add-length" onChange={(e) => setBody(e.target.value)} placeholder="Message" type="text" /> <br />
          <button className="mt-3 border-0 px-2 rounded-3 shadow py-2 yel-col add-length" type="submit">Submit</button>
        </form>
      </div>
    </div>
    <Footer />
  </div>;
}

export default Contact;