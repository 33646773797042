import React from 'react';
import { Link } from "react-router-dom";
import Chip from '../../../common/Chip';
import "./styles.css";

const BlogItem = ({
    blog: {
        id, 
        discription, 
        title, 
        createdAt, 
        authorName, 
        authorAvatar, 
        category,
        cover
    },
}) => (

    <div className="blogItem-wrap">
        <Link to={`/Blogpage/blog/${id}`}><img src={cover} alt="cover" className="blogItem-cover" /></Link>
        <Chip label={category} />
        <Link className="blogItem-link" to={`/Blogpage/blog/${id}`}><h3>{title}</h3></Link>
        <p className='blogItem-desc'>{discription}</p>
        <footer>
            <div className="blogItem-author">
                <img src={authorAvatar} alt="avatar" />
                <div>
                    <h6>{authorName}</h6>
                    <p>{createdAt}</p>
                </div>
            </div>
            <Link className="blogItem-link" to={`/Blogpage/blog/${id}`}>→</Link>
        </footer>
    </div>

);

export default BlogItem