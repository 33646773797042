import React, { useState } from "react";
import Testimonials from "./Subcomponents/Testimonials";
import Navbar from "./Subcomponents/Navbar";
import Footer from "./Footer";
import { Fade } from "react-awesome-reveal";
import { Slide } from "react-awesome-reveal";
import { Zoom } from "react-awesome-reveal";

function About() {
  const [divIndex, setDivIndex] = useState(1);
  const [displayText, setDisplayText] = useState(
    "Providing comprehensive educational programs and skill-building initiatives that empower young people with the knowledge and practical skills necessary for personal growth, career development, and community engagement."
  );
  const [image, setImage] = useState(
    "myImages/education-and-skills-development.jpg"
  );
  const [clickedButton, setClickedButton] = useState(null);
  const [showAim, setShowAim] = useState(
    <div className="shadow-sm w-100 me-2 mt-2 mb-2 pt-4 rounded bg-white aim-body-mob">
      <img className="rounded-3 m-2" height="200px" src={image} alt="" />
      <p className="text-center who pb-4" style={{ fontSize: 18 }}>
        {displayText}
      </p>
    </div>
  );

  const desc = [
    "Providing comprehensive educational programs and skill-building initiatives that empower young people with the knowledge and practical skills necessary for personal growth, career development, and community engagement.",
    "Fostering leadership qualities and empowering young individuals to become agents of positive change in their communities. We strive to enhance their confidence, decision-making abilities, and problem-solving skills, enabling them to address local challenges effectively.",
    "Our mission is to empower and uplift young individuals by providing them with the tools, resources, and opportunities needed to unlock their potential, foster personal growth, and become active contributors to positive change in their lives and communities.",
    "Advocating for youth rights, equal opportunities, and policies that support the needs and aspirations of young people. We work to amplify youth voices, influencing decision-makers and stakeholders to create an enabling environment for youth development and participation.",
    "We value and celebrate the diversity of young individuals. Our organization is committed to fostering an inclusive environment where all young people feel welcome, respected, and valued. We strive to create spaces that embrace diversity, promote equality, and encourage cross-cultural understanding and collaboration.",
    "Building a vibrant and inclusive network of young individuals, organizations, and stakeholders committed to youth empowerment and sustainable development. We foster collaboration, knowledge sharing, and mutual support to maximize our collective impact.",
    "Promoting research and innovation to continuously adapt and enhance our programs and approaches. By staying informed about emerging trends, challenges, and opportunities, we ensure that our organization remains at the forefront of youth empowerment and sustainable development.",
    "",
  ];

  const images = [
    "myImages/education-and-skills-development.jpg",
    "myImages/leadership-and-teamwork.jpg",
    "myImages/community-engagement.jpg",
    "myImages/youth-rights.jpg",
    "myImages/diversity.jpg",
    "myImages/youth-networking.jpg",
    "myImages/research-and-innovation.jpg",
  ];

  const aims = (
    <Slide direction="down">
      <div className="shadow-sm w-100 me-2 mb-2 pt-4 rounded bg-white aim-body-mob text-center">
        <img className="rounded-3 m-2" height="180px" src={image} alt="" />
        <p className="text-center who pb-4" style={{ fontSize: 18 }}>
          {displayText}
        </p>
      </div>
    </Slide>
  );

  const handleAimChange = (text, image, buttonIndex) => {
    setDisplayText(text);
    setImage(image);
    setClickedButton(buttonIndex);
    setDivIndex(buttonIndex);
  };

  const getButtonClass = (buttonIndex) => {
    return buttonIndex === clickedButton ? "clicked-aim" : "";
  };

  return (
    <div>
      <Navbar />
      <div className="about-us-bg">
        <div className="about-us text-white justify-content-center">
          <h1 className="text-center">About Us</h1>
          <div className="red-line-short mb-2 mt-2"></div>
          <h5 className="text-center pt-2">Exceptional is who we are</h5>
        </div>
      </div>
      <div className="container text-center">
        <div className="who">
          <Slide>
            <h3 className="text-center">WHO WE ARE</h3>
          </Slide>
          <div className="red-line-short mb-2 mt-2"></div>
          <p className="" style={{ fontSize: 18 }}>
            We are Rainbow. Rainbow is an acronym for;{" "}
            <strong>Righteous</strong>,<strong> Acceptable </strong>,{" "}
            <strong> Influential</strong>, <strong> Noble</strong>,{" "}
            <strong> Bold</strong>, <strong>Outstanding</strong> and{" "}
            <strong>Worthy</strong>. Rainbow Youtheens Ministry is a registered
            NGO in Nigeria that equips young people with the right knowledge and
            skills needed to lead better lives while contributing positively to
            the sustainable development of communities.
            <br />
            <br />
            Through our vision and mission, we strive to create a world where
            young people are equipped with the necessary tools, resources, and
            support to lead meaningful lives and actively contribute to building
            thriving and sustainable communities.
            {/* Our establishment is devoted to raising Godly and exceptional
                young people in different communities across Nigeria and the
                World at large. As a family, we are dedicated to reaching young
                people with the gospel of love, hope and reconciliation in
                Christ Jesus which transforms all aspect of their lives. Many
                young people are lost and confused, Rainbow Youtheens points
                them to the right direction. We are also a non-denominational
                family that is committed to it's vision, mission, and mandate
                towards accomplishing set goals and objectives. */}
          </p>
        </div>
        <div className="row matters-arising">
          <div className="col-md-4">
            <Fade direction="left">
              <div className="pt-3 pb-2 rounded-3 vmm">
                <h4>VISION</h4>
                <p className="text-center who" style={{ fontSize: 18 }}>
                  To create a future where every young person is equipped with
                  the knowledge, skills, and opportunities to lead fulfilling
                  lives and make meaningful contributions to the sustainable
                  development of their communities.
                </p>
              </div>
            </Fade>
          </div>
          <div className="col-md-4 mt-3">
            <Fade direction="top">
              <div className="pt-3 vmm rounded-3 mx-auto w-75">
                <h4>MOTTO</h4>
                <p className="kc-mission" style={{ fontSize: 18 }}>
                  SALT AND LIGHT <br />
                  (Matthew 5:13-14)
                </p>
              </div>
            </Fade>
          </div>
          <div className="col-md-4">
            <Fade direction="right">
              <div className="pt-3 pb-2 rounded-3 vmm">
                <h4>MISSION</h4>
                <p className="text-center mx-auto who" style={{ fontSize: 18 }}>
                  Our mission is to empower and uplift young individuals by
                  providing them with the tools, resources, and opportunities
                  needed to unlock their potential, foster personal growth, and
                  become active contributors to positive change in their lives
                  and communities.
                </p>
              </div>
            </Fade>
          </div>
        </div>

        <div className="who mt-3">
          <h3 className="text-center">WE AIM TO ACHIEVE THIS BY</h3>
          <div className="red-line-short mb-2 mt-2"></div>
        </div>

        <div className="border border-1 mt-3 mb-3 px-2 rounded-3 aim-div bg-light">
          <div className="aim-head text-right ps-3 py-2">
            <button
              className="bg-transparent border border-0 text-right w-100"
              onClick={() => handleAimChange(desc[0], images[0], 1)}
            >
              <h6 className={`rounded-start p-2 each-aim ${getButtonClass(1)}`}>
                Education and Skill Development
              </h6>
            </button>
            {divIndex === 1 ? aims : null}
            <button
              className="bg-transparent border border-0 text-right w-100"
              onClick={() => handleAimChange(desc[1], images[1], 2)}
            >
              <h6 className={`rounded-start p-2 each-aim ${getButtonClass(2)}`}>
                Leadership and Empowerment
              </h6>
            </button>
            {divIndex === 2 ? aims : null}
            <button
              className="bg-transparent border border-0 text-right w-100"
              onClick={() => handleAimChange(desc[2], images[2], 3)}
            >
              <h6 className={`rounded-start p-2 each-aim ${getButtonClass(3)}`}>
                Community Engagement and Sustainable Development
              </h6>
            </button>
            {divIndex === 3 ? aims : null}
            <button
              className="bg-transparent border border-0 text-right w-100"
              onClick={() => handleAimChange(desc[3], images[3], 4)}
            >
              <h6 className={`rounded-start p-2 each-aim ${getButtonClass(4)}`}>
                Advocacy and Policy Influence
              </h6>
            </button>
            {divIndex === 4 ? aims : null}
            <button
              className="bg-transparent border border-0 text-right w-100"
              onClick={() => handleAimChange(desc[4], images[4], 5)}
            >
              <h6 className={`rounded-start p-2 each-aim ${getButtonClass(5)}`}>
                Inclusivity and Diversity
              </h6>
            </button>
            {divIndex === 5 ? aims : null}
            {}
            <button
              className="bg-transparent border border-0 text-right w-100"
              onClick={() => handleAimChange(desc[5], images[5], 6)}
            >
              <h6 className={`rounded-start p-2 each-aim ${getButtonClass(6)}`}>
                Network and Collaboration
              </h6>
            </button>
            {divIndex === 6 ? aims : null}
            <button
              className="bg-transparent border border-0 text-right w-100"
              onClick={() => handleAimChange(desc[6], images[6], 7)}
            >
              <h6 className={`rounded-start p-2 each-aim ${getButtonClass(7)}`}>
                Research and Innovation
              </h6>
            </button>
            {divIndex === 7 ? aims : null}
          </div>
          <div className="shadow-sm w-75 mt-2 me-2 mb-2 pt-4 rounded-end bg-white aim-body">
            <img className="rounded-3 m-2" height="200px" src={image} alt="" />
            <p className="text-center who pb-4" style={{ fontSize: 18 }}>
              {displayText}
            </p>
          </div>
        </div>
      </div>
      <section id="about-kc" className="meet-our-team text-white">
        <h3 className="mot-top text-center">Meet The President</h3>
        <div className="red-line-short mb-5 mt-2"></div>
        <div className="container-fluid d-flex justify-content-between">
          <div className="mx-auto">
            <img src="myImages/Aunty_Kelechi.webp" alt="Linda Kelechi" />
            <h4 className="mt-2">Linda Kelechi Eronmhonsele</h4>
          </div>
          <div className="text-start">
            <p style={{ fontSize: 18 }}>
              I am an award-winning youth life coach and consultant, and I am
              passionate about empowering and guiding young individuals to
              discover their true potential and lead fulfilling lives. As a
              dedicated youth life coach, I am committed to making a positive
              impact by helping young people navigate the challenges they face
              and supporting them in their personal, academic and career growth.
              <br />
              <br />
              With 15 years of experience working with young people, I have
              developed a deep understanding of their unique needs and
              aspirations.
              <br />
              <br />
              My coaching approach is built on trust, empathy, and active
              listening, creating a safe and non-judgmental space for the youth
              to explore their thoughts, feelings, and goals.
            </p>
          </div>
        </div>
        <div className="container-fluid">
          <p style={{ fontSize: 18, textAlign: "start" }}>
            My mission is simple: I plant the child, train the teenager, and
            prepare the youth.I hold the prestigious Unsung Heroes Award for
            Best Teens Mentor, She Leads(BBC Africa) Award, PFN Award, etc.
            {/* for
            Excellence, RSG Award for Youth Development, and more. */}
            <br></br>
            You can contact me via email:{" "}
            <a href="mailto:lindaemihe@gmail.com">lindaemihe@gmail.com</a>
          </p>
        </div>
      </section>
      <div id="testimonies"></div>
      <div>
        <Testimonials />
      </div>
      <Footer />
    </div>
  );
}

export default About;
