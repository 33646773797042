import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import "./Login.css";
import { useUserAuth } from "../../context/UserAuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { logIn, googleSignIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await logIn(email, password);
      navigate(-1);
    } catch (err) {
      if (err.message === "Firebase: Error (auth/user-not-found).") {
        return setError("User not Found");
      }
      if(err.message === "Firebase: Error (auth/wrong-password).") {
        return setError("Wrong Email or Password")
      }
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate(-1);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handlecloseButton = (e) => {
    // e.preventDefault();
    navigate(-1);
  }

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100 login__main">
      <div className="login__box rounded-3">
        <div className="" style={{textAlign:'right'}}><Link to="" onClick={handlecloseButton}><FontAwesomeIcon icon={faClose} style={{fontSize:'26px', color:'black'}} /></Link></div>
        <h2 className="pb-4">Login</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <form onSubmit={handleSubmit}>
          <div className="login__input__div">
            <label className="text-start" htmlFor="email-address">
              Email Address
            </label>
            <br />
            <input
              className="my-2"
              type="email"
              placeholder="Email Address"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="login__input__div">
            <label className="" htmlFor="password">
              Password
            </label>
            <br />
            <input
              className="my-2"
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button
            className="login__btn login__btn__email rounded-pill my-2 px-1"
            type="Submit"
          >
            <strong>Login</strong>
          </button>
        </form>
        <div className="d-flex">
          <hr />
          <span className="px-2 fw-bold">OR</span>
          <hr />
        </div>
        <button
          className="login__btn login__google rounded-pill mb-2 mt-1 px-5"
          onClick={handleGoogleSignIn}
        >
          <img src="myImages/Google__G__Logo.png" alt="Google logo" height="24" width="24" />
          <strong className="ps-3">Login with Google</strong>
        </button>
        {/* <br />
          <button className="login__btn login__facebook rounded-pill my-2 px-5">
            <FontAwesomeIcon
              icon="fa-brands fa-facebook"
              className="fa-facebook"
            />
            <strong className="ps-3">Login with Facebook</strong>
          </button> */}

        <div>
          <Link style={{ textDecoration: "none" }} to="/login/resetpassword">
            Forgot Password?
          </Link>
        </div>
        <div>
          Don't have an account?{" "}
          <Link style={{ textDecoration: "none" }} to="/signup">
            Sign up
          </Link>{" "}
          now.
        </div>
      </div>
    </div>
  );
};

export default Login;
