import React from 'react';
import Icons from "./Icons"
import { Fade } from "react-awesome-reveal";
import { Link } from 'react-router-dom'

function Cards() {
  return <div >
    <div className="App-header">
      <h1>RECENTS POSTS</h1>
      <div className="underline-1"></div>
    </div>
    <div className="row">
      <div className="col-md-4 mb-3 ">
        <Fade smooth direction='left'>
          <div className="card shadow card-1 rounded-3">
            <Link to='/Blogpage/blog/1' style={{ textDecoration: 'none', color: 'black' }}>
              <img src="/myImages/Self-Esteem1.webp" className="w-100 img-radius" alt="services" style={{ height: '60%' }} />
              <div className="card-body">
                <h6>Self Esteem</h6>
                <div><p>May 5th, 2022.</p></div>
                <p className="text-right">Self-esteem is having a balanced mindset about yourself. A lot of things
                  can contribute to an unstable mindset...
                </p>
              </div>
            </Link>
            <Icons link={"https://rainbowyoutheens.com/Blogpage/blog/1"} bgColor={'white'} />
          </div>
        </Fade>
      </div>
      <div className="col-md-4 mb-3">
        <Fade smooth bottom="bottom">
          <div className="card shadow card-1 rounded-3">
            <Link to='/gallery' style={{ textDecoration: 'none', color: 'black' }}>
              <img src="/myImages/cum.webp" className="w-100 img-radius" alt="services" style={{ height: '60%' }} />
              <div className="card-body">
                <h6>Community Development</h6>
                <div><p>May 5th, 2022.</p></div>
                <p className="text-right">We hold the saying, “empower a teenager with the right information, and
                  you have liberated a whole community from collapsing".
                </p>
              </div>
            </Link>
            <Icons link={"https://rainbowyoutheens.com/gallery"} bgColor={'white'} />
          </div>
        </Fade>
      </div>
      <div className="col-md-4 mb-3">
        <Fade smooth direction="right">
          <div className="card shadow card-1 rounded-3">
            <Link to="/Blogpage/blog/5" style={{ textDecoration: 'none', color: 'black' }}>
              <img src="/myImages/the_enterprising_youth.webp" style={{ height: '60%' }} className="w-100 img-radius" alt="services" />
              <div className="card-body">
                <h6>The Enterprising Youth</h6>
                <div><p>May 5th, 2022.</p></div>
                <p className="text-right">Every youth is relevant by what he or she contributes. The world at large is in
                  dare need of enterprising young people...
                </p>
              </div>
            </Link>
            <Icons link={"https://rainbowyoutheens.com/Blogpage/blog/5"} bgColor={'white'} />
          </div>
        </Fade>
      </div>
    </div>
    <div className='mt-4 mb-4'><Link to='/blogpage' style={{ textDecoration: 'none', color: 'white' }} className="btn-1"><strong>SEE MORE POSTS</strong></Link></div>
  </div>;
}

export default Cards;
