import React, { useState, useEffect } from "react";
import { Button, Modal, Container, Row, Col, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";

function Donate({ displayDonate, sendDatatoParent }) {
  const [customAmount, setCustomAmount] = useState(2500);
  const [selectedOption, setSelectedOption] = useState("");

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    handleAmountChange(value);

    // If the selected option is "Other", set customAmount to an empty string
    if (value === "Other") {
      setCustomAmount();
    }
  };

  const handleAmountChange = (string) => {
    const value = parseInt(string);
    setCustomAmount(value);
    console.log("Custom Amount:" + customAmount);
    console.log("Target:" + string);
  };

  const handleCustomAmountChange = (event) => {
    const value = event.target.value;
    setCustomAmount(value);
  };

  const sendDatatoParentComp = () => {
    sendDatatoParent("none");
  };

  // const [amount, setAmount] = useState(0);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const isInputFilled = email.trim() !== "" && name.trim !== "" && phone !== "";

  const config = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: Date.now(),
    amount: customAmount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: email,
      phone_number: phone,
      name: name,
    },
    customizations: {
      title: "Thanks for your donation",
      description: "Donation made to Rainbow Youtheens",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  return (
    <div className="position-absolute" style={{ display: `${displayDonate}` }}>
      <div className="donate-overlay">
        <div className="text-dark">
          <div className="d-flex justify-content-end">
            <button
              className="btn rounded-circle me-2 my-2 btn-dark shadow-sm"
              onClick={sendDatatoParentComp}
            >
              <FontAwesomeIcon icon={faXmark} className="fs-4" />
            </button>
          </div>
          <div className="mx-3 text-start shadow p-3 rounded-3 bg-white">
            <h2>Make a Donation</h2>
            <p className="fs-6">
              Every donation, no matter how big or small, makes a significant
              difference to our course. Thank you for doing your part to help.
            </p>
            <form action="">
              <div className="mb-3 align-items-center">
                <div className="row gap-2">
                  <div className="col-md-3">
                    <input
                      type="text"
                      className="form-control"
                      id="fullname"
                      aria-describedby="textHelp"
                      placeholder="Your name"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      aria-describedby="emailHelp"
                      placeholder="Your email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <input
                      type="number"
                      className="form-control no-number-scroll"
                      id="phone"
                      aria-describedby="textHelp"
                      placeholder="Your phone number"
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <label className="fs-6 mb-1">
                  <span className="my-1">
                    <b className="red-text">* </b>Please fill your name, a
                    verified email and phone number to proceed <br />
                  </span>
                  Donation Amount:
                </label>
                <div className="fs-6 row mt-0 mx-1 my-2">
                  <div className="form-check col-md-2">
                    <input
                      type="radio"
                      id="option1"
                      name="options"
                      value="2500"
                      checked={selectedOption === "2500"}
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="option1" className="ms-2">
                      ₦ 2,500
                    </label>
                  </div>

                  <div className="form-check col-md-2">
                    <input
                      type="radio"
                      id="option2"
                      name="options"
                      value="5000"
                      checked={selectedOption === "5000"}
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="option2" className="ms-2">
                      ₦ 5,000
                    </label>
                  </div>

                  <div className="form-check col-md-2">
                    <input
                      type="radio"
                      id="option3"
                      name="options"
                      value="10000"
                      checked={selectedOption === "10000"}
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="option3" className="ms-2">
                      ₦ 10,000
                    </label>
                  </div>

                  <div className="form-check col-md-2">
                    <input
                      type="radio"
                      id="option4"
                      name="options"
                      value="25000"
                      checked={selectedOption === "25000"}
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="option4" className="ms-2">
                      ₦ 25,000
                    </label>
                  </div>

                  <div className="form-check col-md-2">
                    <input
                      type="radio"
                      id="option5"
                      name="options"
                      value="50000"
                      checked={selectedOption === "50000"}
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="option5" className="ms-2">
                      ₦ 50,000
                    </label>
                  </div>

                  <div className="form-check col-md-2">
                    <input
                      type="radio"
                      id="option6"
                      name="options"
                      value="Other"
                      checked={selectedOption === "Other"}
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="option6" className="ms-2">
                      Other
                    </label>
                  </div>

                  {selectedOption === "Other" && (
                    <div className="form-check col-auto my-2 text-right">
                      <input
                        type="number"
                        className="form-control"
                        id="otheramount"
                        aria-describedby="textHelp"
                        placeholder="Amount"
                        value={customAmount}
                        onChange={handleCustomAmountChange}
                      />
                    </div>
                  )}
                </div>
                <div className=" fs-6">
                  <div className="">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="email-update"
                    />
                    <label
                      className="form-check-label ms-2"
                      htmlFor="email-update"
                    >
                      I would like to receive occassional updates via email.
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-grid gap-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={!isInputFilled}
                  onClick={() =>
                    handleFlutterPayment({
                      callback: (response) => {
                        console.log(response);
                        closePaymentModal();
                      },
                      onClose: () => {},
                    })
                  }
                >
                  <b className="fs-5">Next</b>
                </button>
              </div>
            </form>
            <h4 className="text-center mt-2">OR</h4>
            <p className="fs-6 bg-primary text-white p-1 rounded-3">
              You can also utilize direct bank transfers to contribute:
              <br />
              <strong>Account Name:</strong> Rainbow Youtheens Ministry <br />
              <strong>Account Number:</strong> 1025374821 <br />
              <strong>Bank: </strong>Zenith Bank.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Donate;
