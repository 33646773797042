import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./UploadedBlog.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

function UploadedBlog({
  title,
  minRead,
  imageUrl,
  description,
  date,
  index,
  id,
  uid,
}) {
  const [text, setText] = useState("");

  useEffect(() => {
    var desc = document.getElementsByClassName("post__description");
    setText(desc[index].innerText);
    console.log(text);
  }, []);

  console.log(uid);

  return (
    <div className="each__blog rounded-3 p-2 shadow mb-2 me-2">
      <div className="d-flex justify-content-between">
        <div className="img__container">
          <img className="blog__img shadow-sm" src={imageUrl} alt="" />
        </div>
        <div className="text-start ps-1">
          <h6>Title: {title}</h6>
          <p>Minutes Read: {minRead}</p>
        </div>
      </div>
      <div>
        <div
          className="post__description d-none"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <span>{text.length > 100 && `${text.substr(0, 100)}...`}</span>

        <span className="btn-group">
          <button
            type="button"
            className="btn btn-white"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </button>
          <ul className="dropdown-menu dropdown-menu-end">
            <li className="li-none">
              <Link to={`/preview/${id}`} className="text-decoration-none">
                <button className="dropdown-item" type="button">
                  Preview
                </button>
              </Link>
            </li>
            <li className="li-none">
              <Link
                to={`/edit/${id}`}
                state={uid}
                className="text-decoration-none"
              >
                <button className="dropdown-item" type="button">
                  Edit
                </button>
              </Link>
            </li>
            <li className="li-none">
              <button className="dropdown-item" type="button">
                Delete
              </button>
            </li>
          </ul>
        </span>
      </div>
      {/* <div>{title}</div>
      <div>{minRead}</div>
      <div>{imageUrl}</div>
      <div className="post__description" dangerouslySetInnerHTML={{ __html: description}}  />
      <div>{date}</div>
      <div>Dazz it</div> */}
    </div>
  );
}

export default UploadedBlog;
