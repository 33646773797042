import React from 'react';
import "./styles.css";

const Header = () => (
    <div className="header-bg home-header">
        <h2>Inspiring messages just for you...</h2>
        <h1 className='my-2'>
            <span>"</span>Rainbow Youtheens Blog<span>"</span>
        </h1>
        <p className='hd-w-25'>This is the place where transformation can take place in your life,
            open your heart to life empowering posts </p>
    </div>
);


export default Header