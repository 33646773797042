import React from "react";
import "./style-academy.css";
import Navbar from "../Subcomponents/Navbar";
import Footer from "../Footer";

function Academy() {
  return (
    <div className="">
      <Navbar />
      <header id="header" className="bg-academy">
        <div className="">
          <img
            className="rounded-3"
            src="myImages/young_boy_learning.webp"
            alt="Youth Learner"
          />
          <img
            className="rounded-3"
            src="myImages/edtech_africa.webp"
            alt="African Learner"
          />
          <img
            className="rounded-3"
            src="myImages/girl_learning_on_laptop.webp"
            alt="Female Learner"
          />
        </div>
        <div className="text-white">
          <h1 className="text-start py-4">Foundation Academy</h1>
          <p className="text-start w-75">
            Foundation academy is a school under the arm of Rainbow Youtheens
            where young people across the globe are being trained and equipped
            with the knowledge and skill they need to grow spiritually, morally,
            psychologically, academically and financially to impact positively
            on the society.
          </p>
          <div className="text-start">
            <a
              href="https://forms.gle/wiZjrCE9qtnrD6h16"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", marginRight: "10px" }}
            >
              <button className="academy-register yel-col py-2 px-3 border-0 rounded-3 shadow">
                Register
              </button>
            </a>
            <a href="#more-about-FA">
              <button className="academy-learn-more text-white py-2 px-3 rounded-3">
                Learn More
              </button>
            </a>
          </div>
        </div>
      </header>
      <article className="">
        <section id="more-about-FA" className="mb-5 pb-4 my-3">
          <h2 className="my-4">What is FOUNDATION ACADEMY?</h2>
          <img
            className="academy-w-30 mb-4 course-card"
            // src="myImages/undraw_questions.svg"
            src="myImages/FoundationAcademy.jpg"
            alt=""
          />
          <p className="w-76 mx-auto">
            The Foundation Academy is an online school where young people across
            the globe are being trained and equipped with the knowledge and
            skills they need to grow spiritually, morally, psychologically,
            academically and financially to impact positively on society.
            <br />
            <br />
            Rainbow youtheens Academy comprises of courses on Spiritual growth,
            leadership, mental /health fitness, emotional stability,
            career/goals/ambition and financial intelligence.
            <br />
            <br />
            Admission is totally free ! Plus CERTIFICATES are issued upon
            graduation!
            <br /><br />
            Send us a message on Facebook to get enrolled. You can make the most
            of this year by beginning with the Academy. "RYT Foundation Academy,
            inspiring young people to be great...".
          </p>
        </section>
        <section className="container-sm my-3">
          <h2 className="mb-4">
            WHY <span className="">FOUNDATION ACADEMY</span> WORKS
          </h2>
          <div className="foundation-academy">
            <div className="academy-w-30">
              <img
                className="w-75 mx-auto my-2 mt-2"
                src="myImages/undraw_community.svg"
                alt=""
              />
              <h3 className="mt-3">Follow-up System</h3>
              <p className="w-77">
                Our priority is that every youth experiences a total
                transformation and this has made us to be concerned about the
                well-being of the student. Hence, the need for an effective
                communication system between the teachers and students. We don’t
                just leave them to figure it out on their own as most of them
                face complex challenges, engaging in conversations establishes a
                trust worthy atmosphere and also exposes us to their area
                weaknesses to help build them up.
              </p>
            </div>
            <div className="academy-w-30">
              <img
                className="w-75 mx-auto my-2 mt-5"
                src="myImages/undraw_group_selfie.svg"
                alt=""
              />
              <h3 className="mt-2">Diversity</h3>
              <p className="w-77">
                Foundation academy is an academy centered at inspiring young
                people for greatness. It is second to none because of the
                diversity of her structures and ability to carry a wide audience
                of young people along, both in the local and foreign
                communities. We also possess training skills that are universal
                which enables us accommodate and communicate with any youth from
                different parts of the world.
              </p>
            </div>
            <div className="academy-w-30">
              <img
                className="w-75 mx-auto my-2 mt-4"
                src="myImages/undraw_online.svg"
                alt=""
              />
              <h3 className="mt-2">Fully Online</h3>
              <p className="w-77">
                Networking is the new market they say. Foundation academy is in
                the business of moulding every youth into a total person to
                unleash their God given potentials, in order to do this, we must
                stay connected 24/7. We offer every youth and teenager an
                opportunity for an uninterrupted access to information as well
                as our resource persons.
              </p>
            </div>
          </div>
        </section>
        <section id="courses" className="py-2">
          <h2 className="py-3 text-white">COURSES</h2>
          <div className="foundation-academy container-sm my-3">
            <div className="card course-w course-card mb-3 overflow-hidden">
              <div className="overflow-hidden">
                <img
                  src="myImages/A_New_Beginning.jpg"
                  className="course-img card-img-top courses-scale"
                  alt="..."
                />
              </div>
              <div className="card-body">
                <h4 className="card-title">Module 1: Creating A New Beginning</h4>
                <p className="card-text">
                  A new beginning is the first step to becoming. The process of
                  learning, unlearning and relearning is the first step. The
                  experiences you had in the past, your identity, association,
                  character, the opinions or knowledge you had in the past all
                  shed of and take a new turn. In the new beginning, what
                  matters is that which God has said concerning you and his
                  expectations for you, nothing else!.
                </p>
                <div className="dropdown">
                  <button
                    className="yel-col border-0 px-3 py-2 rounded-3 dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    See Courses
                  </button>
                  <ul
                    className="dropdown-menu bg-secondary"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item text-white">- Sonship</li>
                    <li className="dropdown-item text-white">- Identity</li>
                    <li className="dropdown-item text-white">
                      - Understanding GOD's Love and Forgiveness
                    </li>
                    <li className="dropdown-item text-white">
                      - Discovery of Purpose
                    </li>
                    <li className="dropdown-item text-white">
                      - Ambition and Goals
                    </li>
                    <li className="dropdown-item text-white">
                      - A Disciplined Lifestyle
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card course-w course-card mb-3 overflow-hidden">
              <div className="overflow-hidden">
                <img
                  src="myImages/Growing_Spiritually.jpg"
                  className="course-img card-img-top courses-scale"
                  alt="..."
                />
              </div>
              <div className="card-body">
                <h4 className="card-title">Module 2: Growing Spiritually</h4>
                <p className="card-text">
                  God has adopted and called every man into his divine realm,
                  there is nothing ordinary about you. You have the potentials
                  of God laying inside but you can never come to comprehend this
                  if you are not growing. Spiritual growth is very vital in
                  order to manifest fully the purpose God has predestined you
                  for, every young person who yearns for a relationship with
                  God, must desire growing spiritually..
                </p>
                <div className="dropdown">
                  <button
                    className="yel-col border-0 px-3 py-2 rounded-3 dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    See Courses
                  </button>
                  <ul
                    className="dropdown-menu bg-secondary text-white"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item text-white">
                      - Growing Intimacy with God via Word Study{" "}
                    </li>
                    <li className="dropdown-item text-white">
                      - Growing Intimacy with God via Prayer
                    </li>
                    <li className="dropdown-item text-white">
                      - Baptism of the Holy Spirit{" "}
                    </li>
                    <li className="dropdown-item text-white">- Divine Direction</li>
                    <li className="dropdown-item text-white">
                      - How to Discover, Develop and Deploy your Talent and
                      Gifts
                    </li>
                    <li className="dropdown-item text-white">- Sonship 2</li>
                    <li className="dropdown-item text-white">
                      - Breaking free from Fear{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card course-card course-w mb-3 overflow-hidden">
              <div className="overflow-hidden">
                <img
                  src="myImages/Emotional_Stability.webp"
                  className="course-img card-img-top courses-scale"
                  alt="..."
                />
              </div>
              <div className="card-body">
                <h4 className="card-title">Module 3: Emotional Stability</h4>
                <p className="card-text">
                  Having emotions doesn’t make you evil, it only makes you
                  human. But, if not handled properly can be a tool for
                  self-destruction. Gaining mastery of your emotions will enable
                  you make the right decisions in life. Emotional stability is a
                  course that will give every youth an understanding that
                  emotions don’t define them and they have the will power to
                  determine the outcome. .
                </p>
                <div className="dropdown">
                  <button
                    className="yel-col border-0 px-3 py-2 rounded-3 dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    See Courses
                  </button>
                  <ul
                    className="dropdown-menu bg-secondary text-white"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item text-white">- Personality</li>
                    <li className="dropdown-item text-white">
                      - Anger Management{" "}
                    </li>
                    <li className="dropdown-item text-white">
                      - Dealing with Peer Pressure
                    </li>
                    <li className="dropdown-item text-white">
                      - Dealing with Addictions
                    </li>
                    <li className="dropdown-item text-white">
                      - How to Increase Productivity
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="overflow-hidden">
          <h2 className="cert">CERTIFICATES</h2>
          <div
            id="carouselExampleIndicators"
            className="carousel carousel-acad slide"
            data-bs-ride="carousel"
          >
            <div id="slider-img" className="carousel-inner carousel-inner-acad">
              <div className="carousel-item carousel-item-acad active">
                <img src="myImages/Prisca_Louis.webp" className="w-60" alt="..." />
              </div>
              <div className="carousel-item carousel-item-acad">
                <img src="myImages/Anita_Patrick.webp" className="w-60" alt="..." />
              </div>
              <div className="carousel-item carousel-item-acad">
                <img
                  src="myImages/Phildorah_Chinedu.webp"
                  className="w-60"
                  alt="..."
                />
              </div>
              <div className="carousel-item carousel-item-acad">
                <img
                  src="myImages/Miracle_Kingdom.webp"
                  className="w-60"
                  alt="..."
                />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </section>
      </article>
      <Footer />
    </div>
  );
}

export default Academy;
