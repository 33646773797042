import React, {useEffect} from 'react'
import {useParams} from "react-router-dom"
import { useUserAuth } from '../../../context/UserAuthContext';

function Preview() {
  const {blogID} = useParams();
  // console.log(blogID)
  const {user} = useUserAuth();
  console.log(user);
  useEffect(()=>{
    
  }, [user])

  return (
    <div>
      {blogID}
    </div>
  )
}

export default Preview