import React from 'react';
import GirlsHangout19 from './GirlsHangout19';
import GirlsHangout20 from './GirlsHangout20';
import './style.css'

function GirlsHangout() {
    return (
        <div>
            <GirlsHangout20 />
            <GirlsHangout19 />                        
        </div>
    )
}

export default GirlsHangout