import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../../context/UserAuthContext";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { db } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";

function IsLoggedIn() {
  const { user, logOut } = useUserAuth();
  const [isEditor, setIsEditor] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      if (user && user.uid) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          const userData = docSnap.data();
          if (userData && userData.editor === true) {
            setIsEditor(true);
          }
        }
      }
    };
  
    fetchUser();
  }, [user]);
  
  

  if (!user) {
    // console.log(user);
    return (
      <Link to="/login" className="nav-link active glow">
        LOGIN
      </Link>
    );
  }

  const handleLogOut = async (e) => {
    e.preventDefault();

    try {
      logOut();
      alert("Logged Out Successfully.");
    } catch (err) {
      // console(err.message);
    }
  };

  return (
    <div className="dropdown">
      <button
        className="btn btn-secondary rounded-circle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <FontAwesomeIcon icon={faUser} />
      </button>
      <ul
        className="dropdown-menu dropdown-menu-end"
        aria-labelledby="dropdownMenuButton1"
      >
        <li className="li-none">
          <button className="dropdown-item" href="#">
            Welcome, {user.displayName}
          </button>
        </li>
        <li className="li-none">
          <hr className="dropdown-divider" />
        </li>
        {isEditor ? <>
          <li className="li-none">
          <button className="dropdown-item" href="#" onClick={() => navigate("/editorspage")}>
           Editor's Page
          </button>
        </li>
        <li className="li-none">
          <hr className="dropdown-divider" />
        </li></> : null}
        <li className="li-none">
          <button className="dropdown-item" href="#" onClick={handleLogOut}>
            Log Out
          </button>
        </li>
      </ul>
    </div>
  );
}

export default IsLoggedIn;
