import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom"
import "./EditorMain.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faRotateRight } from "@fortawesome/free-solid-svg-icons";
// import Footer from "../../Footer";
import UploadedList from "../UploadedList";
import EditorsProfile from "../EditorsProfile";

function EditorsPage() {
  const [isReload, setIsReload] = useState(false);

  useEffect(() => {
    
  }, [isReload]);

  return (
    <div className="">
      <div className="wrapper">
        <EditorsProfile text="Add New Blog" url_path="/addblog"/>
        <div className="two shadow">
          <div className="d-flex justify-content-between px-3 py-2 text-white">
            <h4>Posts</h4>
            <button onClick={() => setIsReload(!isReload)} className="h4 border-0 bg-transparent text-white">
              <FontAwesomeIcon icon={faRotateRight} />
            </button>
          </div>
          <div>
            <UploadedList />
          </div>
          <button className="text-end add-button text-white bg-transparent border-0 rounded-circle shadow">
            <Link to="/addblog" className="text-white"><FontAwesomeIcon icon={faCirclePlus} className="faCirclePlus" /></Link>
          </button>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default EditorsPage;
