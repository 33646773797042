import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Academy from "./Academy";
import Blog from "../pages/Blog";
import Blogpage from "../pages/Blogpage";
import MainGallery from "./MainGallery";
import Login from "./Login";
import ProtectedRoute from "./ProtectedRoute";
import SignUp from "./SignUp";
import ResetPassword from "./Login/ResetPassword";
import Event from "./Event";
import EditorsPage from "./Editors/EditorsPage";
import AddBlog from "./Editors/AddBlog";
import Edit from "./Editors/Edit"
import Preview from "./Editors/Preview"
import Donate from "./Subcomponents/Donate";
import PitchEvent from "../pages/PitchEvent";

function Router() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />}></Route>
      <Route path="/About" element={<About />}></Route>
      <Route path="/Contact" element={<Contact />}></Route>
      <Route path="/Gallery" element={<MainGallery />}></Route>
      <Route path="/Academy" element={<Academy />}></Route>
      <Route exact path="/Blogpage" element={<Blogpage />}></Route>
      <Route path="/pitch-event" element={<PitchEvent />}/>
      <Route
        path="/Blogpage/blog/:id"
        element={
            <Blog />
        }
      ></Route>
      {/* <Route
        path="/Blogpage/blog/:id"
        element={
          <ProtectedRoute>
            <Blog />
          </ProtectedRoute>
        }
      ></Route> */}
      <Route path="/signup" element={<SignUp />}></Route>
      <Route path="/login/resetpassword" element={<ResetPassword />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/donate" element={<Donate />}></Route>
      <Route path="/Event" element={<Event />}></Route>
      <Route path="/editorspage" element={<EditorsPage />}></Route>
      <Route path="/addblog" element={<AddBlog />}></Route>
      <Route path="/edit/:blogID" element={<Edit />}></Route>
      <Route path="/preview/:blogID" element={<Preview />}></Route>
    </Routes>
  );
}

export default Router;
