import React, {useState, useEffect} from 'react'
// import { useParams } from 'react-router';
import {db} from "../../firebase"
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { useUserAuth } from '../../context/UserAuthContext';
import UploadedBlog from "./UploadedBlog"

// todo: 1.Get all the uploaded Blogs from Firestire 
// todo: 2.Load them into UploadedBlog with map
function UploadedList() {
  const [BlogList, setBlogList] = useState([])
  const {user} = useUserAuth()

  useEffect(() => {
    const q = query(
      collection(db, `blogger: ${user.uid}`),
      orderBy("createdAt", "desc")
    );
    onSnapshot(q, (querySnapshot) => {
      setBlogList(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
          date: doc.data().createdAt.toDate(),
        }))
      );
    });
  }, [user]);

  console.log(BlogList);

  return (
    <div className='d-flex flex-wrap'>
      {BlogList.map((blogItem, index) => {
        return (
          <UploadedBlog
              id={blogItem.id}
              key={blogItem.id}
              index={index}
              uid={user.uid}
              date={`${blogItem.date.toLocaleTimeString()}, ${blogItem.date.toDateString()}`}
              // edited={comment.data.edited}
              imageUrl={blogItem.data.imageUrl}
              minRead={blogItem.data.minRead}
              title={blogItem.data.title}
              description={blogItem.data.description}
              // blogId={id}
            />
        )
      })}
    </div>
  )
}

export default UploadedList