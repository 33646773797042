import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Login.css";
import { Alert } from "react-bootstrap";
import { useUserAuth } from "../../../context/UserAuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { resetPassword } = useUserAuth();
  const navigate = useNavigate();

  const handlePasswordReset = async (e) => {
    e.preventDefault(e);
    setError("");
    console.log("clicked");

    try {
      await resetPassword(email);
      alert("Password reset email sent!");
      navigate("/login");
    } catch (err) {
      setError(err.messsage);
    }
  };

  const handlecloseButton = async (e) => {
    e.preventDefault();
    navigate(-2);
  }

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100 login__main">
      <div className="login__box rounded-3">
        <div className="" style={{ textAlign: "right" }}>
          <Link to="" onClick={handlecloseButton}>
            <FontAwesomeIcon
              icon={faClose}
              style={{ fontSize: "26px", color: "black" }}
            />
          </Link>
        </div>
        <h2 className="pb-4">Reset Password</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <div className="login__input__div">
          <label className="text-start" htmlFor="email-address">
            Email Address
          </label>
          <br />
          <input
            className="my-2"
            type="email"
            placeholder="Email Address"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button
          className="login__btn login__btn__email rounded-pill mt-4 px-5"
          type="Submit"
          onClick={handlePasswordReset}
        >
          <strong>Submit</strong>
        </button>
        <div className="mt-3" style={{ textAlign: "start" }}>
          
          <Link style={{textDecoration: 'none'}} to="/login"><FontAwesomeIcon icon={faArrowLeft} /> Return to Log in</Link>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
