
export const blogList = [
    {
        id: 1,
        title: "Self Esteem",
        category: "Self Esteem",
        subCategory: ["Positivity", "Knowledge"],
        discription: `Self-esteem is having a balanced mindset about yourself. A lot of things can contribute to an unstable mindset, especially when you are too conscious about what others think about you or what you let certain situations or experiences make out of you. You were made for God and no one else and this clearly implies that nothing else has the right or power to define.
                        \nSelf-esteem is a topic that can't be neglected in all ramifications, it is what sponsors healthy relationships with people which in turn yields trustworthiness. This is pertinent to unlocking the best version of yourself. When you lack of knowledge on WHO YOU ARE and the amazing qualities inherent in you, you become trapped by living the life of others and what’s more dangerous, you’ll fall just for anything. You are a special kind of brand established unto good works, there’s nothing wrong with you but you should pay head to the fact that a good company as well as block buster opportunities are attracted to a brand that knows who he or she is.
                        \nThe realm of impossibilities thrives beyond the borders of an average mentality. You cannot harbor negative perceptions and expect to be able to hold on to a healthy lifestyle. A double minded man is so unstable.  Hence the need to tackle misconceptions on your esteem. You cannot harbor negative perceptions and expect to be able to hold on to a healthy lifestyle. As you read this, do away with every wrong opinion that you might have embraced about yourself and lean forward for the God opinion about you. Truly understanding self-esteem requires self-awareness on both your strength and weaknesses and how well you let these things either build you up or tear you down.
                        \nThere are two types of self-esteem, which are low self-esteem and healthy self-esteem. To get an elaborate explanation on the disparity between the two types of self-esteem, kindly click on the link. If you must have a balanced mindset, pay attention the following factors;
                         \nMINDSET: Your thoughts can either improve your self-esteem or weigh it down. It is your thinking that forms the perception you have about yourself. As long as you can think above yourself you can get anything done. It is also very important to note that they are negative thought patterns launched into the air by the words of certain of people or situations surrounding you.As they fall like crumbs, pack them all up for dispose, you have the power to guard your mind! No thought is more powerful beyond consent given on to invade your actions.
                        \nASSOCIATION: When you keep pondering about what others say about you, you may be forced into having a low perception about yourself. Anyone can say what they want, you really can’t hinder them, but that’s their perception, don’t let those words define you. Keep the right kind of company that you can leverage on to discover the best version of yourself and not the kind that drives you to the edge with constant competition to prove who you aren’t. Walk out of every association or relationship where the second partly constantly bullies you. This could lead to low self-esteem. KNOW YOUR WORTH!
                        \nDISABILITIES:  Everyone has a disability, it could be mental limitations, stuttering, personality disorder, it could also be ones behavior but don’t let yours become a big deal or issue.
                        \nGen 1:26 NLT "So God created man in his own image’ in the image of God created he him; male and female created he them".
                        \n God made you in his image and likeness despite the deformities. Deformities are temporal which may occur during child birth. God did not create you deformed. You are fearfully and wonderfully made
                         \nPsalm 139; 14 NLT "I will praise thee; for I am fearfully and wonderfully made: marvelous are thy works; and that my soul knoweth right".
                        \nTo mention but a few, other factors that may contribute to self-esteem includes; Experience at home or school, environments and assumption problems. One thing you must always remember is that your parents, friends and colleagues are not in the position to define you, only God your creator has that power. You are royalty and there’s so much you can do if you live by this. Small mindedness indeed cripples creativity. Your life never changes until you lift the expectations you have of yourself.`,                 
        createdAt: "May 5th, 2022",  
        authorName: "Adrienne Francis", 
        authorAvatar: "/myImages/adrienne.jpg",        
        cover: "/myImages/wyp-2.webp"
    },
    {
        id: 2,
        title: "Good Leadership",
        category: "Leadership",
        subCategory: ["Communication", "Knowledge", "Diligence"],
        discription: `Leadership is the willingness to serve. A good leader is a servant and servants are keepers not rulers. Leadership is beyond just heading a position. An office or position does not make you a leader, but the value inside of you and that value is a product of investment with the desire to empower others. Leaders don’t search for platforms just to flaunt prowess, they themselves become the platform for the growth of others. The realm of leadership strives on the border of becoming who you are and then, it extends to influencing others to become too. Leadership is first being, then doing. It is the ability to inspire others to become and fulfill themselves by you doing same. Visions are sometimes, not enough to influence people. It takes a lot of skills, hard work and commitment in being a good leader.\n

        One skill a good leader must possess is the ability to listen and be teachable. There is an old saying which goes, “No man is an island”. One can learn from anyone. A leader desires to make impact but he or she must be able to understand that learning precedes impact and the life of a leaders is a university of learning. Secondly a leader must possess the skill of Trust Gaining. You should know how to buy the minds of your followers. The truth remains that people only get influenced by what a leader knows, does and communicates.\n
        
        A good leader is one who possesses necessary skills is self-aware of his strengths and weaknesses and that of his team. Leadership is a mindset, it is the perception you have of yourself and why you where born. As a means of striking a balance between these two edges, a good leader prioritizes personal development not just for himself but for his team members as well. Every leader must be able to recognize his sphere of influence, you’re called to cause influence in your family, school, business workplace and even in the believers’ gathering.\n
        
        In that sphere of yours, leveraging continually on personal development will in no small measure expand your influence. A leader is a generational voice, every of his or her actions are well thought, not just for the now but for the future. He or she must conscious of how they treat their predecessors or followers secrets, how they treat their affairs, how they handle their matters and how they appear in front of a role. In leadership your integrity should be felt. Your followers should be able to hand you their trust depending on how you display certain attitude or handle certain this shows you are truly a good leader. As simple as this may sound, if there's no diligence in serving people, in this part, you've failed as a leader even before the eyes of God.
        
        
        \nA good leader should be the first at keeping instructions. First to heed, first to attend and appear and first to respond. Another important skill a good leader should possess is THE SPEAKING ABILITY. A blind man cannot certainly lead a dozen of blind men out of a ditch because he lacks one requisite which is sight. It is same for any leader who lacks THE SPEAKING ABILITY.
        The speaking ability is simply the ability to speak. To speak, generally has been defined as conveying information or expressing a feeling. Speaking surpasses verbal communication unto nonverbal communication. The demeanor, aura and behavior a leader exhibits sometimes, speaks louder than words orally expressed. Every effective leader must have the Speaking Ability. It is at the center of leadership. Since leadership has generally been pictured as the ability to influence others, a leader can only manifest such influence through the speaking ability. What distinguishes leaders from others is their ability to expressly communicate ideas, thoughts, commands, through speeches and their body language which allows for influence to be begotten even in the midst of silence.\n
        
        In even clearer words, the Speaking Ability can be broken into the bits of Communication and Charisma. Whilst Communication is summed in the technique of speaking well verbally, Charisma is summed mainly in presentation and expressing ideas without the vocal organs. The ability to articulate ideas combined with the force of personality drives the wheels of effective capacity to command influence with ease. Charisma essentially follows communication as it is embodied in the tricks of tone, body language, and emotional availability. You must know that ordinary speakers deliver information but emotional availability makes a leader understand that the information he is to convey has to match with the needs and desires of others who share their vision. External factors aside, it is a leader's responsibility to be sensitive to his audience. Nobody likes the man who dominates a conversation, constantly interrupts and engages chiefly in a monologue rather than a dialogue. Attend to the responses your audience makes. It engages their minds and consequently, boosts your Charisma.
        The words communicated, the ideas passed through body language, the poise and confidence through which these ideas are passed sums up the Speaking Ability which leaders must possess. You necessarily do not need to sweat your head too much around the possibility of acquiring this ability as a leader. The speaking ability is a skill. And like all skills, it can be learnt! Every leader must study his dynamics, his dynamics is what makes him exceptional.
        
        `,  
        createdAt: "September 16th, 2022", 
        authorName: "Adrienne Francis", 
        authorAvatar: "/myImages/adrienne.jpg",        
        cover: "/myImages/girls20.webp"
    },
    {
        id: 3,
        title: "Spiritual Growth",
        category: "Spiritual Growth",
        subCategory: ["Growth", "Knowledge", "Service"],
        discription: `What are you a follower of Christ?
        If all you view the life of a believer as is a place to make impact, then where do you learn from?
        
        If your answer to the question above is just to make impact, then, a glint of pride is hidden in them. What pride does to you is that, it makes you feel that where they're seated, you’re too good that all you want is to disperse only.
        
        \n1Cor.10.12 Don't be so naive and self-confident. You're not exempted. You could fall flat on your face as easily as anyone else. Forget about self-confidence; it's useless. Cultivate God-confidence.
        
        \nPride does not announce itself, It doesn’t reduce your prayer or spiritual life, in fact you can even have more fire while being proud. Nobody has PhD where God’s word is concerned nobody has PhD where temptations are concerned. The scripture is beckoning on every believer to take heed, let those of you who think you are scholars of the word or possess knowledge too much that all you want to do is spit it out stop being naïve. We only know the much God reveals to us and the impact He makes through us. You could fall flat like anyone, self-confidence is useless in spiritual matters. We learn every second. Don’t shut yourself from learning or having people you feel you can only learn from.
        
        \nYou are not restricted to learning from children or learning from people who don’t even know God. Learning is a lifelong process, you learn from your parents, your siblings, even your students as a teacher. Selfishness will creeping into your midst when everyone is trying to prove a point. God doesn't want points proven, he wants love shown, He also wants support shown. These can only happen when we stop doing individualistic adventures and stop thinking we know more than the one who leads us. Don't subject yourself to the thought that you're too spiritual or saying you want to impact only.
        
        \nHow then do you learn?
        A follower of Christ is a disciple of Christ and a disciple is a lifetime learner. What are the tools that aids a disciple from falling flat like the scripture read we read above?
        
        \n1. Collectivism;
        Collectivism is the opposite of individualism It’s team work, team spirit. It’s death to self, it is stepping out of I’m important to we’re important. In the kingdom of God, we don’t seek to outshine, we blend shine.  You cannot be a disciple and all you do is show up like a foreigner just to disperse only and disappear afterward. Come down from your high horse, spiritual growth starts from being available to be taught. Jesus learnt from everyone, Jesus humbled Himself. Jesus should be our role model. Why not sow a seed that will speak for you in time to come?
        
        \nDeuteronomy 32:2 (KJV) “My doctrine shall drop as the rain, my speech shall distill as the dew, as the small rain upon the tender herb and as the showers upon the grass”. Let the teaching of Jesus fall into your heart like showers.
        
        \n2. A learning heart
        A disciple must be hungry for more outpouring, as you study, you get revelations that will in time to come be your head light on the journey of fulfilling purpose. Luke 6:40 KJV The disciple is not above the master; but everyone that is perfect shall be as his master.
        
        \n3. Never think/feel you have arrived
        My question to you reading is this? Why are you a disciple and who is your teacher?
        Luke 6:40 makes us understand that an apprentice doesn't lecture the master. The point is to be careful who you follow as disciple teacher.
        
        \nLearning is a lifelong process. Never let self-make you outgrow the process else you fall flat. You experience a switch on and switch off in your growth spiritually because you haven't understood that learning precedes impact, you can't give what you haven't learnt or cultivated. The true test of impact is making what you've learnt a lifestyle first.
        `,   
        createdAt: "October 6th, 2022", 
        authorName: "Adrienne Francis", 
        authorAvatar: "/myImages/adrienne.jpg",        
        cover: "/myImages/character.webp"
    },
    {
        id: 4,
        title: "Discover Your Potential",
        category: "Talent",        
        subCategory: ["Capacity", "Knowledge", "Service"],
        discription: `\nOne of the major questions young people ask is how to know or differentiate between what their gifts and talents are.

        \nA talent is an untapped inherent potential, skill or ability for being good at a particular activity. It is inherent meaning it exists inside or you were born with it, it could also be inherited.
        
        \nUntapped meaning not being used yet but existing in large quantity that could bring profits or benefits. A gift is a reformed or processed ability to do something well for profiting i.e adding to yourself and those around you. A talent is inbuilt, and it brings profits or benefits; Platforms, contracts, awards etc. It is a refined version of a talent that adds value, not just to you but other people. Note that profit and value are not the same thing. It a refined version because it is for doing something well, while a talent is for doing something good. How do you do something well? Through series of training 
        
        \nNow these two pertain to the area of purpose discovery but many people mistake gifting here for spiritual gifts, yes they're gifts too but gifts of the spirit and it pertains to MINISTRY by ministry I don't mean church.
        
        \nRef: 1 Corin 12: 4,7-10 the saying that goes,  " Proper preparation meets opportunities".
        
        \nDiscovering, development and deployment are growth realms for purpose discovery, you can also call them growth formula. They're the templates for preparation in order to meet the desired opportunities our gifts and Talents create for us.
        
        \nRef: Proverbs 18:16. A room is amount of space (access) that is needed for a particular purpose, and this scripture is telling us that our gifts carves the space for us because we have a purpose. What is the purpose of these gifts & talents? : To positively influence your world not for you to sit on them, for maximum impact on your world also not for mere decoration. 
        
        \nPeter Marshall said, "The measure of life is not in duration but in donation".
        
        \nGifts create the access for you and then brings you (plans out of the interactions between your gifts) and the great opportunity/people ahead of you.
        
        \nSo let's find out what these 4 growth realms are:
        
        Discovery:
        \nSimply means to dig deep, to come to an awareness on a thing that exist but you never knew it did. It is important because, until you know who you are and what you have, you will continue to live the lives of others.
        
        \nDiscovering your gifts and talents demands the consciousness of your environment. How you interact with your environment contributes to what you have discovered inside of you. Your environment also centers on relationships and the things you get intimate with on daily basis, the things you hear and see as well as your intimacy with God.
        
        \nWhat you get intimate with births what you uncover or discover. So it is quite safe to say that Intimacy is a precursor to discovery. 
        
        \nYou must also be hungry to discover. There must be a level of hunger stirred up as that is what fuels your drive to dig deep into the improved version of you with your gifts and talents. Refuse to settle for little, but be aware that appetite can be destructive if not fed from the right source. So many wrong notions and patterns are out to corrupt you, that is why you must above all things stay intimate with God. Because, the more time you spend with him, the more you begin to look like God and express God!
        
        \nSelf-assessment and asking relevant questions can get you to the right information. You might have personally accessed yourself little but asking the relevant questions can awaken your thought pattern to see yourself for who you really Are! If you don't ask question, how do you discover? How then do you improve?
        
        \nYou ask questions to become AWARE. Awareness is simply coming to the knowledge of your strength and weaknesses. In discovery never live for perfection, live for progress why? Because the knowledge you have now is progressive, it must change in order to get to that version for your gifts and talents.
        
        \nSecond stage Developing: 
        \nTo develop means to become larger or more advanced. There is no limit to how far you can go in life, but you can't achieve beyond the level of what you know. Your job at this stage is to eat and eat and eat! Don’t just have a voracious appetite to know but to also to develop. How? With information; information makes the difference in you.
        
        \nRising in life is a function of the information you have. God wants to use you to do great things with your gifts and Talents but we must first unlearn and learn. The day you identify with your gift and talent, you must be well informed about that craft for effective delivery. To develop, you must expose your gifts and talents to CONSTANT growth.
        You must consume yourself with the desire to grow that you begin to shed off all the things that were wrong and did not align with what God has created you with.
        
        \nHow do you shed off your old self? By unlearning, relearning and learning.
        Development is a process and process sustains your gifting. It is a training ground where proper information is built! And proper information brings effectiveness!
        
        \nHow do you build proper information?
        
        \nEngage your mind; forgo the past, think innovation, think change, think new systems
        \nRef: Phil 3:12-13 GNB. Grab opportunities or trainings available to fine tune your gifting to what it should be.
        
        \nThe media space has also made things easier, watch videos to improve, read books, enroll for trainings as well. All these are tools you can use to further advance yourself, but first, you must discover who you are in Christ and by the help of His Spirit, He will guide you to discover great potentials in you.
        `,   
        createdAt: "November 23rd, 2022", 
        authorName: "Adrienne Francis", 
        authorAvatar: "/myImages/adrienne.jpg",        
        cover: "/myImages/the3d.webp"
    },
    {
        id: 5,
        title: "The Enterprising Youth",
        category: "Enterprise",        
        subCategory: ["Capacity", "Knowledge", "Service"],
        discription: `\nThe world is on a constant roller coaster of revolution, in order to stay relevant in each season of revolution, as a youth, there is need to build value to eliminate the act of being trampled upon or rolled away. Your value is how you add season to the season of revolution, and, the best way to catch up on the train of global impact is to be enterprising.

        \nThe old saying goes, "Economic success is a result of talent and enterprise ".
        
        \nThe Oxford dictionary of Current English defines the word 'enterprising' as showing initiative and resourceful.
        \nAn enterprising youth is one who is willing to try out new, unusual ways of doing or achieving something. He or she shows initiative and resourcefulness by creating relevant ideas, quick and clever ways to solve a problem or overcome difficulties while earning from it.
        
        \nEvery youth is relevant by what he or she contributes. Society, firms, industries, organizations, the world at large is in dare need of enterprising young people. People who are distinguished by their excellent imagination or initiative, ready to undertake projects of importance or difficulty, or unattempt systematic plans for achieving an aim and also energetic in carrying out any undertaking.
        
        \nA lot of youth across the globe are held bound by the dependence syndrome, they're only taught how to stay on the receiving end from their parents, friends etc. They are never taught how to also be on the giving end, where independency comes in. The seldom truth is this, you must give value to gain not just financial freedom but all-round freedom. At a young age, they're not taught the importance of being creative or doing something to develop ones skills and earn a living.
        
        \n" I've been so caught up with my educational pursuit that I never taught to develop my gifts and talents", says young Deola. This is what a lot of young people are facing today. The best way to be enterprising is to employ those things that make you unique, into productive use. Your ideas, gifts, talent, time, knowledge, effort, all these are your ticket to stardom and there is no harm developing or monetizing them. 
        
        \nThe resolutions of an enterprising youth are; smart work, self-investment, diligence, creativity, ability to recognize opportunities, revenue minded.
        
        \nDue to the wide spread of the right information, many young people in countries far and wide today are getting wiser. They are escaping from the queue of those depending on their families, the government or employers for financial help, opportunities or jobs. And the good news is that these countries are lands of great opportunities, especially for those who are willing to take the plunge. The enterprising class of youth are embracing one line of business or the other, and they are making a decent living out of it.
        
        \nA myopic mind will rather justify their lack and losses rather than looking for what the problem is and tackling it for great success.
        A myopic mind will also define prosperity as something that only God plays a role. Prosperity does not omit the participation of man.
        
        \nOne of the dynamic speakers in America today, ' Mike Murdock', said in his book, 31 Reasons people do not receive their financial harvest, " I read where one of the wealthiest men in America gives his child only $5.00 a week, because he wants him to learn to respect money, if he makes it too easy for him, he will not understand how difficult it is to generate ".
        
        \nMoney is a tool.
        \nMoney is a weapon against ignorance.
        Money makes you a burden-bearer instead of a burden. Money is not necessary because it makes you happy but because it solves problems.
        When you disrespect money as a young person, your ability to attract it will vanish.
        
        \nDeuteronomy 28:12 - The Lord shall open unto thee His good treasure, the heaven to give the rain until thy land in His season, and to bless all the work of thine hand"
        
        \nGod has said he will bless the work of your hand, but as an enterprising youth who desires God's blessings in the area of your pursuit be it your dream, career, purpose etc.
        \nWhat is the work of your hand?
        
        \n-  Your vision
        \n-  Your ideas
        \n-  Your gift and talents
        
        \nSmart work implores you to recognize an opportunity and think outside the box in order to solve the problems associated with it. You have been told what money is, when you solve problems for others, you generate revenue. Think outside the box doesn't refer to complex things but those small things you can creatively give a solution to. How can you creatively help that pure water industry suffering from poor delivery? Or how can you use your gifts and talents to create a scheme.
        
        \nGet busy with something, there are so many lucrative skills young people can venture into like; Wed development, graphic design, fashion designing, blogging, content creation, catering, baking other businesses like logistics, drop shipping,   interior designing, printing stores etc. 
        
        \n-  An enterprising youth is not idle, they equip themselves with skills and these skills enable then know what to do per time.
        
        \n-  An enterprising youth is on who knows how important he or she is to the growth of the society and smart works his way towards it.
        
        \nAn enterprising youth is always available for growth, to learn what is necessary for him or her. Many young people overrun the resources made available to them but one who is enterprising makes good use of resources. Social Media, YouTube is not seen as an avenue to while away time but as an opportunity to grow. Subscribe to people or avenues in line with your interest, follow people in line with your interest. The process might not yield immediate result but it has created a room for growth. Keep Growing! Even God is not in agreement with stagnancy.  The world is evolving, your today’s best will eventually become stale, so be willing to grow beyond past achievements.
        
        \nIsaiah 43: 18-19
        \n"Remember ye not the former things, neither consider the things of old. Behold, I will do a new thing". 
        
        \nBe willing to serve with your uniqueness, it doesn’t mean you need to be a pushover, or jump at every opportunity that comes your way or allow people control your choices. Be willing to learn, unlearn and relearn. Be open minded to realize better way things work. It is a wise thing to introduce yourself to new things, don’t ever depend on your present knowledge for future relevance.
        
        \nSets a standard and stick to it regardless. Every young person who desires to build an enterprise someday should never settle for less. Never keep standards based on the status quo but stick with your standard especially when you have built value that will certainly make people stick with you.
        
        \nAn enterprising youth doesn’t feel entitled to people’s money when he or she can’t show them something worth their time and money. Invest in things that will help your present and your future. Don’t procrastinate or be lazy minded, think ahead, It will save you more than you think. 
        
        \nInvest in yourself, don’t be lazy to know. No one will be willing to patronize you if you can’t prove to them they won’t regret it. Focus on building yourselves at an early stage now. Make future investments.
        
        \nA wise man once said, your time and effort is worth more than market value. Choose what you spend your time and effort on, never spend on what will never yield something profitable for you. What is your worth? As times are changing will your worth still speak for you?
        
        \nEnterprising young people are the new creative. Every day dreams are being aborted. Put your creativity to work, take up challenges that will open a new chapter for you. Consistency is the New market place, be consistent in selling value, you have all it takes.
        
        `,  
        createdAt: "Febrauary 20th, 2023", 
        authorName: "Adrienne Francis", 
        authorAvatar: "/myImages/adrienne.jpg",        
        cover: "/myImages/the_enterprising_youth.webp"
    },
    {
        id: 6,
        title: "Saving for Tomorrow",
        category: "Finance",        
        subCategory: ["Savings", "Wealth Creation", "Financial Management"],
        discription: `\nFinancial literacy is a crucial skill that every young person should possess. Understanding how to manage money, make informed financial decisions, and plan for the future can pave the way for a secure and prosperous life. In this article, we will explore key principles of financial literacy and provide valuable tips to help youth build a strong financial foundation.

        \n1. Budgeting: Mastering Your Money:
        
        \nOne of the fundamental pillars of financial literacy is budgeting. Create a budget to track your income and expenses, allowing you to allocate funds wisely and ensure that you live within your means. List your sources of income and all regular expenses, ensuring that your expenses do not exceed your income. Budgeting will help you identify areas where you can cut back and save more. Learn how to prioritize essential expenses, set savings goals, and differentiate between needs and wants. Budgeting empowers you to take control of your finances and make intentional choices.
        
        \n2. Saving: The Power of Compound Interest:
        
        \nSaving money is an essential habit that can yield significant long-term benefits. Start saving early by setting aside a portion of your income regularly. Educate yourself about the power of compound interest and explore different saving options, such as high-interest savings accounts or Registered Education Savings Plans (RESPs). By cultivating a savings mindset, you can build an emergency fund, save for future goals, and develop financial resilience. Remember it is called “Savings" for a reason. It could save you on latter days..
        
        \n3. Credit and Debt: Borrow Responsibly:
        
        \nCredit cards and loans can be valuable financial tools, but they also come with responsibilities. Learn about credit scores, interest rates, and how to borrow responsibly. Understand the impact of credit card debt and prioritize paying off high-interest debt first. Establish healthy credit habits early on to ensure a solid credit history, which can be essential for future financial endeavors like renting an apartment or obtaining a mortgage.
        
        \n4. Investing: Growing Your Wealth:
        
        \nInvesting may seem intimidating, but it is a powerful tool for growing your wealth over time. Familiarize yourself with different investment options, such as stocks, bonds, and mutual funds. Consider opening a Tax-Free Savings Account (TFSA) or a Registered Retirement Savings Plan (RRSP) to take advantage of tax benefits. Start small, do your research, and seek guidance from professionals if needed. Investing early can maximize the potential for long-term growth and financial security.
        
        \n5. Financial Education: Seek Knowledge:
        
        \nContinuously educate yourself about personal finance. Explore reputable resources, attend workshops or webinars, and read books on financial literacy. Understand concepts like inflation, risk management, and the importance of diversification. The more knowledge you acquire, the better equipped you will be to make informed financial decisions.
        Also, don't hesitate to seek guidance from financial professionals. Consider consulting a financial advisor who can provide personalized advice based on your goals and circumstances. They can help you develop a comprehensive financial plan, navigate investment options, and offer insights on tax planning and retirement savings.
        
        \nFinancial literacy is an essential life skill that empowers youths to make informed decisions about money. By mastering budgeting, saving, understanding credit and debt, exploring investment opportunities, and continuously educating yourself, you can build a strong financial foundation for a prosperous future. Take control of your financial journey today and embrace the power of financial literacy. Remember, your financial decisions today can shape your financial well-being tomorrow.`,

        createdAt: "March 3rd, 2023", 
        authorName: "Adrienne Francis", 
        authorAvatar: "/myImages/adrienne.jpg",        
        cover: "/myImages/savings.jpg"
    },
    {
        id: 7,
        title: "Discovering the Key to Greatness",
        category: "Purpose",        
        subCategory: ["Personal Growth", "Success", "Development"],
        discription: `\nIn a world full of possibilities, every youth holds within them the potential for greatness. The key to unlocking this potential lies within each individual, waiting to be discovered and nurtured. It's time to embark on a journey of self-discovery and unleash the extraordinary within you.

        \nPotential knows no bounds and is not the exclusive domain of a select few. It resides within the depths of every human being. Oftentimes, we become fixated on comparing our progress to that of others, inadvertently overlooking the unique path we must tread to realize our full potential. The purpose of this article is to provide invaluable guidance to youths who are still in search of that elusive key to unlock their vast potential.
        
        \nPRIORITY ON POTENTIAL, NOT CREDENTIALS
        Potential represents the latent abilities within an individual, capable of being honed to facilitate future success. It is akin to a seed, awaiting nurturing and development to yield bountiful fruits with far-reaching impact. It may seem small and insignificant at first, but its true value lies in your ability to recognize and harness it. Don't get too caught up in the recorded successes of others without acknowledging their individual journeys. Prioritize discovering your own potential, and the credentials will follow.
        
        \nHere are some key steps to consider as you unlock your potentials:
        
        \n1. Passion: Dive deep within yourself and identify the activities that ignite a powerful and compelling emotion within you. What do you love doing? By asking yourself this question, you'll gain insights into your potential and find your true calling.
        
        \n2. Willingness to Explore: If you're unsure of your passion, embrace the spirit of exploration. Be open to trying out different activities, learning new skills, and adopting various techniques. In the process of exploration, you'll stumble upon that niche that brings out the best in you.
        
        \n3. Set Goals: Goals serve as road maps on your journey to unlocking your potential. Create an action plan that outlines what you aim to achieve in your chosen field. Break it down into manageable steps and start working towards them. Each milestone you conquer will bring you closer to realizing your full potential.
        
        \n4. Determination and Persistence: Just like a seed takes time to bear fruit, unlocking your potential requires determination and persistence. Stay motivated in your chosen path, embrace continuous learning and growth, and never give up. Enjoy the process of development, knowing that the results will make you an outstanding individual.
        
        \n5. Be Bold: The world needs your unique gifts and talents. Once you have unlocked your potential, don't be afraid to share it with your community. Be bold and courageous in expressing your abilities, for it is through your contributions that the world becomes a better place.
        
        \nAt Impact Youtheens, we stand ready to be your guiding light on this journey of self-discovery. Our dedicated team is committed to helping you become the best version of yourself. Together, we can unlock your true potential and make a lasting impact on the world around us.
        
        \nTake that first step today, and let us work together to unleash the extraordinary potential within you. Your greatness awaits!`,

        createdAt: "April 18th, 2023", 
        authorName: "Adrienne Francis", 
        authorAvatar: "/myImages/adrienne.jpg",        
        cover: "/myImages/do-something-great-1.jpg"
    },
    {
        id: 8,
        title: "The crude oil in a bottle or more?",
        category: "Personal Development",        
        subCategory: ["Growth", "Self-Discovery", "Potential", "Refinement"],
        discription: `\nIn the journey of personal growth and development, we can draw inspiration from an unlikely source: crude oil. Crude oil is a very important mineral cum natural resource in Africa. Crude oil has great uses which can only be accessed when it had gone through the process of refining. Refining the crude oil produces several end products such as kerosine, petroleum, diesel fuel and even gasoline. All of which are essential for human existence in the world.

        \nWithout the process of refining, the crude oil is pretty much crude oil with little or zero usefulness. Take a look at lives of people. Isn't there a synergy between the crude oil and man?
       
        \nDear young person, we all are like crude oil. We're born without super great abilities. We are born equal. But not everyone, after maturity remain equal.
        Just like this valuable resource, we are born with immense potential that can only be unlocked through a refining process. As young people, we have the opportunity to refine ourselves and contribute positively to the sustainability and growth of our society. This article explores the process of refining and offers practical steps to become the best version of ourselves.
       
        \n1. Seek Knowledge:
        \nKnowledge is a powerful tool for refinement. Just as books hold the treasure trove of wisdom, they can equip our minds and broaden our horizons. By delving into various subjects, exploring relevant courses, and embracing continuous learning, we can expand our knowledge and develop a strong foundation for personal growth.
       
        \n2. Engage in Healthy Conversations:
        \nConversations provide a platform for expression, exchange, and growth. Surrounding ourselves with like-minded individuals who share our aspirations can be immensely beneficial. By engaging in healthy conversations, we have the opportunity to learn, unlearn, and relearn. Platforms such as Impact Youtheens offer a supportive community of young people eager to make a difference.
       
        \n3. Engage in Self-Evaluation:
       Self-evaluation is a crucial step in the refining process. Reflecting on our actions, mistakes, and achievements allows us to assess our progress and seek areas for improvement. 
       Here's a good story to provoke you. Ken and Sam were wood cutters at a firm. Ken started working at that firm five years before Sam joined the organization. One year after Sam joined the firm, he got a raise and a promotion. None of which Ken had gotten in his five years of service to that firm. Ken was perplexed. He met their employer and asked why Sam earned such reward in such short time of service. The manager responded that the firm was a result oriented firm and appreciated Sam because his output was enormous. He cut down more trees than Ken. He advised Ken to ask Sam his secret. Ken asked Sam and Sam's response was igniting. Sam said, “After I cut down each tree, I take a break for two minutes and I sharpen my axe. When was the last time you sharpened your axe?".
       
       \nIn the story of Ken and Sam, we learn the importance of constantly sharpening our skills and adapting to changing circumstances. Take time to evaluate your own journey, identify areas of growth, and strive for continuous improvement.
       
       \n4. Implementing Changes:
       Acquiring knowledge and evaluating oneself is only valuable when we put that knowledge into action. Implementation is the key to transformation. By applying what we have learned in real-life situations, we can turn our potential into tangible results. Just as crude oil is refined into useful end products, we can transform ourselves into individuals who make a positive impact on our society.
       
       
       \nIn the pursuit of personal growth, it is crucial to prioritize our own refinement. By embracing the refining process, seeking knowledge, engaging in healthy conversations, evaluating ourselves, and implementing positive changes, we can unlock our full potential and contribute to a better future. 
       Remember, refining yourself is an ongoing process. Embrace the journey of growth, and your efforts will not go unnoticed. By prioritizing your personal development and well-being, you lay the foundation for long-term success and fulfillment.
       
       \nWe hope this blog has been useful in guiding you towards your path of refinement. Share your thoughts with us in the comment section below. Together, let's build a generation of empowered youth ready to make a positive impact on the world.`,

        createdAt: "May 10th, 2023", 
        authorName: "Adrienne Francis", 
        authorAvatar: "/myImages/adrienne.jpg",        
        cover: "/myImages/oil-in-a-bottle.jpg"
    },
    {
        id: 9,
        title: "Earning Power: Your Health or Your Wealth?",
        category: "Health and Wealth",        
        subCategory: ["Health and Well-being", "Wealth Creation", "Financial Management"],
        discription: `\nHow do you define your earning power?
        \nDo you evaluate it based on the material results or the ability that engineers such material results?
        The pursuit of financial stability and even career upliftment can be overwhelming that we tend to lose sight of what truly matters.
        
        \nHowever, it's crucial to recognize that our health and well-being play a significant role in determining our overall quality of life. The question arises: Should we prioritize our health or our wealth when considering our earning power? In this article, we'll explore the intricate relationship between earning power, health, and wealth, and how finding a balance is key to long-term happiness and prosperity.
        
        \nThe Pursuit of Wealth:
        
        \nIn a society characterized by consumerism and materialistic aspirations, the pursuit of wealth can become all-consuming. We strive to climb the corporate ladder, maximize our income, and accumulate assets, often at the expense of our physical and mental well-being. However, it's essential to recognize that money alone cannot guarantee happiness or fulfillment. 
        
        \nHealth's Worth:
        
        \nWhile wealth can provide us with comfort and financial security, it is our health that enables us to enjoy the fruits of our labor. What's the joy of the labourer when he's unable to eat from his labour? Your response says it all.
        \nOptimal physical and mental well-being are invaluable assets that money cannot always buy. Without good health, the pursuit of wealth becomes meaningless.
        
         \nGiving priority to our health not only improves our general state of being but also establishes a strong basis for achieving long-term success. When we are in good physical and mental condition, we can operate at our optimal level, take advantage of opportunities, and conquer obstacles. Good health enhances productivity, fosters resilience, and enables us to adapt to the ever-evolving demands of the world.
         
         \nThe Price You Pay for Neglect:
        
         \nNeglecting our health for the sake of wealth can lead to detrimental consequences. Chronic stress, burnout, and health issues can arise from overworking, neglecting self-care, and sacrificing our well-being for financial gain. In the long run, compromised health can undermine our earning power and diminish the joy and fulfillment we seek. It could lead to prolonged illness or even death. That's sad, right?
         \nI know.
        
        \nFinding a Balance:
        
        \nAchieving a balance between health and wealth is essential for sustainable success and happiness. It's crucial to recognize that both health and wealth are interconnected and mutually reinforcing. By prioritizing our health, we enhance our energy levels, mental clarity, and emotional resilience, enabling us to maximize our earning potential and create wealth more effectively.
        
        \nTo strike a balance, we must invest time and effort in self-care practices that nurture our physical, mental, and emotional well-being. Regular exercise, a nutritious diet, adequate sleep, and stress management techniques are essential for maintaining optimal health. Prioritizing leisure, hobbies, and quality time with loved ones and the right social group or community also contributes to overall well-being.
        
        \nBe intentional about the value you place on both concepts. Understand that true success lies in achieving a harmonious integration of both health and wealth. By aligning our goals and actions with our values and well-being, we can pursue financial growth while maintaining our physical and mental health. This holistic approach allows us to enjoy the benefits of wealth without sacrificing our overall quality of life. The society needs YOU, a complete YOU to make it a better place. Do not sacrifice one on the altar of the other.`,

        createdAt: "July 12th, 2023", 
        authorName: "Adrienne Francis", 
        authorAvatar: "/myImages/adrienne.jpg",        
        cover: "/myImages/balance-and-priorities.jpg"
    },
    {
        id: 10,
        title: "Empowering Youth for Positive Change",
        category: "Leadership",        
        subCategory: ["Personal Growth", "Youth Empowerment", "Skill Enhancement"],
        discription: `\nIn today's society, being equipped with strong leadership skills is essential for creating a prosperous and inclusive future. Leadership goes beyond traditional notions of authority and control—it starts with self-leadership and extends to positively influencing others. In this blog article, we will delve into the intricacies of leadership, explore key leadership competencies for young people, and emphasize the importance of building leadership skills.

        \nFIRST, WHAT IS LEADERSHIP?
        
        \nLeadership can be defined as the ability to impact, inspire, unite, guide, support, energize, provoke, prompt, mobilize, and activate others in the pursuit of a common goal, while simultaneously upholding commitment, momentum, confidence, and courage. This definition, known as the two-tier definition of leadership, offers insights from two different viewpoints that contribute to the understanding of leadership. It suggests that what people commonly perceive as leadership is merely one facet of it, specifically the outward manifestation involving influencing and inspiring others. However, this definition refutes that limited notion by encompassing a broader understanding of leadership.
        
        \nThe common perception of leadership held by most people focuses on its visible outcomes, such as influencing and inspiring others to achieve certain goals. This perspective considers leadership as the external manifestation of guiding and motivating others. It goes beyond these visible actions and encompasses a broader understanding of leadership. 
        
        \nLeadership strives on the foundation of your “becoming" and it then extends to influencing others to become too. Leadership is first “being" then doing. A swift ride to leading people or life itself is engineered by you leading yourself. It begins with you. It is imperative to acknowledge that leadership plays a vital role in the development of youth. It helps them become individuals who are responsible and accountable, especially for the future, even towards the society. It builds confidence, dedicated, resilience and commitment which can enable young people face life challenges with a positive attitude. Leadership skills can also be expressed in decision making process which reflects in every aspect of life, including academics, personal, heath and career.
        
        \nWhen it comes to building leadership, several key competencies are crucial for their development. These competencies provide a foundation for effective leadership and empower youth to make a positive impact in their communities. Here are some key leadership competencies that are essential for young people.
        
        \n1. Effective Communication: 
           \nEffective communication is a vital skill for leaders. Young individuals should learn to express their ideas, actively listen, and communicate with clarity and empathy. Effective communication empowers youth leaders to inspire, motivate, build relationships, and resolve conflicts.
        
        \n2. Teamwork and Collaboration: 
           \nCollaboration is essential for youth to work effectively with diverse teams. Encouraging cooperation, inclusivity, and teamwork enables young people to leverage the strengths of others, build consensus, and achieve common goals.
        
        \n3. Critical Thinking and Problem-Solving: 
           \nDeveloping critical thinking skills equips young leaders to analyze situations, evaluate information, and make informed decisions. Fostering a problem-solving mindset allows youth to approach challenges creatively, explore innovative solutions, and adapt to changing circumstances.
        
        \n4. Emotional Intelligence and Empathy: 
           \nEmotional intelligence plays a crucial role in understanding and managing emotions, both in oneself and others. Cultivating empathy allows young leaders to relate to different perspectives, show compassion, and build strong interpersonal relationships. These qualities contribute to effective leadership and foster inclusivity.
        
        \n5. Resilience and Adaptability: 
           \nLeadership often requires resilience in the face of adversity. Youth should develop the ability to bounce back from setbacks, embrace change, and adapt to new situations. Resilient leaders inspire others to persevere and maintain a positive outlook in challenging circumstances.
        
        \n6. Vision and Goal Setting: 
           \nYouth leaders should have a clear vision of their goals and aspirations. Setting realistic and meaningful objectives enables young people to inspire others, provide direction, and mobilize efforts toward positive change. A compelling vision motivates and guides youth leaders on their leadership journey.
        
        \n7. Ethics and Integrity: 
           Upholding ethical values and acting with integrity is paramount for leaders. Young individuals should understand the importance of ethical leadership, demonstrating honesty, fairness, and accountability. These qualities inspire trust and foster a culture of integrity within their communities.
        
        \nBuilding leadership skills among young individuals is a powerful tool for empowering them to create positive change. By honing essential leadership competencies, such as effective communication, collaboration, critical thinking, emotional intelligence, resilience, vision, and ethics, we equip youth to become influential leaders who shape a brighter future. 
        Impact Youtheens Canada is an organization that embraces the potential of our youth and support their journey in developing these skills, as they hold the power to make a lasting impact on their communities and beyond.`,

        createdAt: "August 2nd, 2023", 
        authorName: "Adrienne Francis", 
        authorAvatar: "/myImages/adrienne.jpg",        
        cover: "/myImages/empowerment-for-positive-change.jpg"
    },
    
];