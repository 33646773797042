import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  updateProfile,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { auth } from "../firebase";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password, name) {
    return createUserWithEmailAndPassword(auth, email, password)
    .then(() => {
      updateProfile(auth.currentUser, {
        displayName: name
      })
    });
  }
  // function updateName(name) {
  //   return updateProfile(auth.currentUser, {
  //     displayName: name
  //   }).then(() => { // Profile Name Uploaded 
  //   }).catch((err) => { // An error occurred 
  //   })
  // }
  function updatePhotoURL(photoURL) {
    return updateProfile(auth.currentUser, {
      photoURL: photoURL
    }).then(() => {// PhotoURL updated!
    }).catch((error) => {// An error occurred 
      console.log("An error occured")
    });
  }
  function logOut() {
    return signOut(auth);
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider)
    // .then();
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email)
  }
  // function getUserPhotoURL(email) {
  //   auth
  //     .getUserByEmail(email)
  //     .then((userRecord) => {
  //       // See the UserRecord reference doc for the contents of userRecord.
  //       console.log(`Successfully fetched user data: ${userRecord.toJSON()}`);
  //     })
  //     .catch((error) => {
  //       console.log('Error fetching user data:', error);
  //     });
  // }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      // console.log("Auth", currentuser);
      setUser(currentuser);
      if (user && user.uid) {
        setDoc(doc(db, "users", user.uid), {
          email: currentuser.email,
          displayName: currentuser.displayName,
          uid: currentuser.uid,
        });
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{ user, logIn, signUp, logOut, googleSignIn, resetPassword, updatePhotoURL }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}