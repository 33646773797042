import React from "react";

function Testimonials() {
  return (
    <div className="my-testimony overflow-hidden">
      <h3 className="text-center mt-4">Testimonials</h3>
      <div className="red-line-short mb-2 mt-2"></div>
      <div id="carouselExampleIndicators" className="carousel carousel-test slide" data-bs-ride="carousel">
        {/* <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
        </div> */}
        <div id="slider-img" className="carousel-inner carousel-inner-test">
          <div className="carousel-item carousel-item-test active" data-bs-interval="20000">
            <div className="d-flex justify-content-center mt-4">
              <div className="w-75 mt-4 medQry-test">
                <p style={{ fontSize: 16 }}>This is my testimony to Youth/Teens out there. When I was 
                 added by a friend I wasn't expecting much, my first class in the academy was impressive. My mentality changed, 
                 the assignment after every class helped in building a stronger intentionality and hunger for knowledge. The assignment was 
                 a gateway to mirror my life and reflect on the teachings that are strictly based on scriptures. I began to discard some unhealthy 
                 patterns I had been holding on to, and placing Godly principles. All the classes and courses I attended in the academy was life 
                 changing and transforming.</p>
                <div className="d-flex justify-content-center">
                  <img style={{ width: '10%', border: 'solid 2px #ff0000' }} className='rounded-circle' src="myImages/deborah_samuel.jpg" alt="squid" />
                  <h5 className="ms-3 align-self-center">Deborah Samuel</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item carousel-item-test" data-bs-interval="20000">
            <div className="d-flex justify-content-center mt-4">
              <div className="w-75 mt-4 medQry-test">
                <p style={{ fontSize: 16 }}>I decided to go again. I joined the 2022 Batch C students. I got weary almost that week. I told myself, 
                you must get that value you came for. Meeting up with assignments and classes made me realize that Foundation Academy helped 
                instill discipline in me. Each module and lesson came with their own value. I began to wonder how I missed that the first time. 
                I thank God for using this foundation to change me. I met amazing people, learnt a lot of things. I didn't regret trying again. Thank 
                you Ma Linda Kelechi Eronmhonsele, and also RAINBOW YOUTHEENS FOUNDATION ACADEMY</p>
                <div className="d-flex justify-content-center">
                  <img style={{ width: '10%', border: 'solid 2px #ff0000' }} className='rounded-circle' src="myImages/onyinye.jpg" alt="squid" />
                  <h5 className="ms-3 align-self-center">Onyinye Anosike</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item carousel-item-test" data-bs-interval="20000">
            <div className="d-flex justify-content-center mt-4">
              <div className="w-75 mt-4 medQry-test">
                <p style={{ fontSize: 16 }}>I want to thank GOD for his faithfulness for making me find myself in this wonderful academy. Thank you, 
                Mrs kelechi Linda Eronmhonsele for everything and thank you to my teachers for your dedication and time. Ever since I have been in this 
                academy I have learnt a lot. It shaped me with knowledge and skills to impact the world and my generation and most especially it really 
                helped me to work on my anger issues. It will really mold you and help you to discover and develop your God given talent and purpose and 
                will give you the knowledge to impact your generation.</p>
                <div className="d-flex justify-content-center">
                  <img style={{ width: '10%', border: 'solid 2px #ff0000' }} className='rounded-circle' src="myImages/prisca-img.jpg" alt="squid" />
                  <h5 className="ms-3 align-self-center">Prisca Louis</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item carousel-item-test" data-bs-interval="20000">
          <div className="d-flex justify-content-center mt-4">
              <div className="w-75 mt-4 medQry-test">
                <p style={{ fontSize: 16 }}>I am grateful for the opportunity to be part of the Rainbow Youtheens, Foundation Academy. Indeed it has shaped 
                my life positively with the amazing teachings, most especially the Topic "A Disciplined Lifestyle". It has also helped me in dealing with 
                my addiction with the Help of God. I'll recommend it to people because a lot of young people don't know who they are in Christ. So I believe 
                with the Rainbow youtheens Academy, young people will get to know more about Christ and how truly they are loved by God. And also discover 
                their purpose. Thank you Auntie Kelechi.</p>
                <div className="d-flex justify-content-center">
                  <img style={{ width: '10%', border: 'solid 2px #ff0000' }} className='rounded-circle' src="myImages/joy_matthew.jpg" alt="squid" />
                  <h5 className="ms-3 align-self-center">Joy Matthew</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Testimonials;