import React, {useState} from "react";
import { Link } from "react-router-dom";
import IsLoggedIn from "./IsLoggedIn";
import Donate from "./Donate";

function Navbar() {
  const [displayDonate, setDisplayDonate] = useState("none");

  const handleDonate = () => {
    setDisplayDonate("block");
  };

  const handleDonateClose = (data) => {
    setDisplayDonate(data);
  }

  return (
    <div>
      <nav
        id="navBar"
        className="navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar overlay"
      >
        <div className="the-blur"></div>
        <div className="container-fluid">
          <Link to="/" id="navbar-brand">
            <div>
              <img
                className="ryt-logo"
                src="/myImages/rytlogonew.webp"
                alt="logo"
              />
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  to="/"
                  className="nav-link active glow"
                  aria-current="page"
                >
                  HOME
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/About" className="nav-link active glow">
                  ABOUT US
                </Link>
              </li>

              <div className="dropdown my-auto mx-auto">
                <button
                  className="btn btn-secondary bg-transparent border-0 dropdown-toggle nav-link active glow mx-auto"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  PROGRAMS
                </button>
                <ul
                  className="dropdown-menu bg-transparent text-center"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li className="nav-item">
                    <Link to="/Academy" className="nav-link active glow">
                      ACADEMY
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/Blogpage" className="nav-link active glow">
                      BLOG
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/Gallery" className="nav-link active glow">
                      GALLERY
                    </Link>
                  </li>
                </ul>
              </div>
              <li className="nav-item mx-auto">
                <button
                  onClick={handleDonate}
                  className="btn text-white nav-link active glow"
                >
                  DONATE
                </button>
              </li>
              <li className="nav-item">
                <Link to="/Contact" className="nav-link active glow">
                  CONTACT
                </Link>
              </li>
              <li className="nav-item">
                <IsLoggedIn />
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {<Donate displayDonate={displayDonate} sendDatatoParent={handleDonateClose}/>}
    </div>
  );
}

export default Navbar;
