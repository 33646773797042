import React from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Navbar from "./Subcomponents/Navbar";
import "../App.css";
import Cards from "../components/Subcomponents/Cards";
import Footer from "./Footer";
import { Slide } from "react-awesome-reveal";
import { Zoom } from "react-awesome-reveal";
import Header from "./Header";
import Testimonials from "./Subcomponents/Testimonials";
import Donate from "./Subcomponents/Donate";

function Home() {
  return (
    <div>
      <Navbar />
      <Header />
      <section className="App-header">
        <Slide smooth direction="right">
          <h1>Welcome to Rainbow Youtheens</h1>
        </Slide>
        <div className="underline"></div>
        <div className="section-1">
          <Slide smooth direction="bottom">
            <p>
              We hold the saying, “empower a teenager with the right
              information, and you have liberated a whole community from
              collapsing”. To help every young person transform into a complete
              person GOD has created them to be as they fully become RAINBOW.
            </p>
          </Slide>
        </div>
      </section>
      <section className="service-container">
        <div className="container">
          <div id="service" className="App-header">
            <h1>Explore Our Services</h1>
            <div className="underline-1"></div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <ul className="service-text">
                <Slide direction="left">
                  <li className="li-none">
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      className="glow"
                      to="/Academy"
                    >
                      <span>-</span>Training
                    </Link>
                  </li>
                </Slide>
                <Slide direction="right">
                  <li className="li-none">
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      className="glow"
                      to="/Blogpage/blog/2"
                    >
                      <span>-</span>Leadership
                    </Link>
                  </li>
                </Slide>
                <Slide direction="left">
                  <li className="li-none">
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      className="glow"
                      to="/Gallery"
                    >
                      <span>-</span>Community Development
                    </Link>
                  </li>
                </Slide>
                <Slide direction="right">
                  <li className="li-none">
                    <a
                      style={{ textDecoration: "none", color: "white" }}
                      className="glow"
                      href="https://www.youtube.com/channel/UCQtog7Px4Nii0Q-zwcvS-6g"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <span>-</span>Coaching
                    </a>
                  </li>
                </Slide>
              </ul>
            </div>
            <div className="col-md-5">
              <img
                className="img-hand"
                src="/myImages/hand-3.webp"
                alt="hand"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="container matters-arising">
        <div className="row">
          <div className="col-md-6">
            <div className="App-header">
              <h2>
                <b className="red-text">Join</b> the Exciting Youth Competition
                by <b>Rainbow Youtheens Ministry!</b>
              </h2>
              <div className="underline-1"></div>
            </div>
            {/* <h4 className="text-start">
              What Should Be At The Top Your Goals List This Year?
            </h4> */}
            <p className="my-4 fw-bold text-right">
              Calling all talented young minds aged 15-25!!!
            </p>
            <p className="text-right">
              Are you passionate about IT, Business/ Entrepreneurship, or
              Creativity? Do you have an existing business? We’ve got an
              exciting opportunity just for you!{" "}
            </p>
            <p className="text-right">
              Get ready for an electrifying showcase of talent and skill in the
              most awaited competition of the year.
            </p>
            <p className="text-right">
              🔹 <b>Theme:</b> PITCH UP: SKILL HIGHER.
              <br />
              🔹 <b>Categories for Entry:</b> IT, General Business, and
              Creativity.
            </p>
            <p className="text-right my-4">
              📅 Entry Period:{" "}
              <b className="red-text">10 August - 10 September, 2023</b>.
            </p>
            <span className="flex-left">
              <Link
                style={{ textDecoration: "none", fontSize: "18px" }}
                className="btn-1 button-bg text-white"
                to="/pitch-event"
              >
                <b>Click to Register</b>
              </Link>
            </span>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <img
              src="myImages/Pitch-Up-Flyer.jpg"
              alt="poster"
              style={{ borderRadius: "15px" }}
              className="shadow w-75 mt-3"
            />
          </div>
        </div>
      </section>

      <section className="container matters-arising">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <img
              src="myImages/FoundationAcademy.jpg"
              alt="poster"
              style={{ borderRadius: "15px" }}
              className="shadow w-75 mt-3"
            />
          </div>
          <div className="col-md-6">
            <div className="App-header">
              <h1>FOUNDATION ACADEMY</h1>
              <div className="underline-1"></div>
            </div>
            {/* <h4 className="text-start">
              What Should Be At The Top Your Goals List This Year?
            </h4> */}
            <p className="text-right">
              The Foundation Academy is an online school where young people
              across the globe are being trained and equipped with the knowledge
              and skills they need to grow spiritually, morally,
              psychologically, academically and financially to impact positively
              on society.
              <br />
              <br />
              Rainbow Youtheens Foundation Academy comprises of courses on
              Discovery of Purpose, Spiritual growth, leadership, mental
              wellness, career/goals/ambition and financial management.
              <br />
              <br />
              Admission is totally free ! Plus CERTIFICATES are issued upon
              graduation!
              <br />
              "RYT Foundation Academy, inspiring young people to be great...".
              <Link to="/Academy">(more)</Link>
            </p>
            {/* <div className="flex-left">
              <a
                href="https://youtube.com/playlist?list=PLrK1Um1ku3GvF1ltsGkQNAgu-5DP8s2Mi"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
                className="btn-1 button-bg text-white"
              >
                <strong>Watch on Youtube</strong>
              </a>
            </div> */}
          </div>
        </div>
      </section>

      <Zoom>
        <section className="container">
          <Cards />
        </section>
      </Zoom>
      <section className="section-2">
        <div>
          <h2 className="sec-2">
            Cultivating Youth and Teens all over the globe
          </h2>
        </div>
        <div className="underline-2"></div>
        <div>
          <h2 className="sec-2">Equipping them with life skills</h2>
        </div>
      </section>
      {/* <section className="container kc-mission">
        <div className="my-mission">
          <h3>MY MISSION:</h3>
          <h4>I Plant The Child, Train The Teenager and Prepare The Youth</h4>
        </div>
        <div className=" flex-row">
          <div className="w-matters-clip mx-auto">
            <ReactPlayer
              url="/myImages/video/aunty_kelechi.mp4"
              controls
              className="matters-clip"
            />
          </div>
          <div className="ps-3">
            <p className="text-right">
            I am an award-winning youth life coach and consultant, and I am passionate about empowering and guiding young individuals to discover their true potential and lead fulfilling lives. As a dedicated youth life coach, I am committed to making a positive impact by helping teenagers navigate the challenges they face and supporting them in their personal and academic growth...
              </p>
            <Link to="/about" style={{ textDecoration: "none" }}>
              <div>
                <button className="btn-1 flex-left">
                  <strong>Learn More</strong>
                </button>
              </div>
            </Link>
          </div>
        </div>
      </section> */}
      <section className="section-3">
        <h2>
          <i>You Have Found Your Place</i>
        </h2>
        <p>Desire is everything, be the radical change you desire</p>
        <Link
          to="/about"
          target="_blank"
          rel="noreferrer"
          className="btn white-text"
        >
          <button className="button mb-btn-size">
            <strong>Meet Like Minds</strong>
          </button>
        </Link>
      </section>
      <section className="container matters-arising">
        <div className="row">
          <div className="col-md-6">
            <div className="App-header">
              <h1>Matters Arising</h1>
              <div className="underline-1"></div>
            </div>
            <p className="text-right">
              Learning everyday is what makes one relevant in the society.
              <br />
              Matters Arising is a monthly series that discusses issues
              surrounding young people by providing them with the right
              information to lead better lives as they unlearn,learn and
              relearn...
            </p>
            <div className="flex-left">
              <a
                href="https://youtu.be/Kbg-se32QJQ"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
                className="btn-1 button-bg text-white"
              >
                <strong>Click to watch on Youtube</strong>
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <img
              src="myImages/MA_April.webp"
              alt="poster"
              style={{ borderRadius: "15px" }}
              className="shadow w-75"
            />
          </div>
        </div>
      </section>
      <section className="container e-book">
        <div className="row flex-row">
          <div className="col-md-6">
            <img
              src="myImages/RSB_2.webp"
              alt="poster"
              className="ebook-img rounded-3 shadow"
            />
          </div>
          <div className="col-md-6">
            <div className="align-left book-header book-container">
              <h1>RAINBOW SERIES Vol.2</h1>
            </div>

            <p className="text-right book-container">
              The youth who makes real impact is the one who makes God the
              centre of it all. The best way to live your life is experiencing
              God daily in family, in relationships, in academics, and in
              career. In this book, you’ll learn how to manage your sphere,
              unlock your potential, build your capacity, and understand God's
              will for your life.
              <br />
              Rainbow Series Volume 2 is the young people's easy-read
              inspirational e-book for self development and spiritual growth. In
              this manual, you'll discover you and find the key to a healthy
              walk with God.
              <br />
            </p>
            <div className="flex-left ms-4">
              <button
                className="btn-1 button-bg"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <strong>Get a Copy</strong>
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li className="li-none">
                  <a
                    className="dropdown-item"
                    href="https://amzn.to/3vj9Ine"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get from Amazon
                  </a>
                </li>
                <li className="li-none">
                  <hr className="dropdown-divider" />
                </li>
                <li className="li-none">
                  <a
                    className="dropdown-item"
                    href="https://www.kobo.com/ww/en/ebook/rainbow-series-vol-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get from Rakuten Kindle
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="section-4">
        <h2>
          <i>
            "Life isn't wished for, life isn't played for, Life is deliberate!
            Pursue life with utmost zeal and aspirations"
          </i>
        </h2>
      </section>
      <section className="container App-header">
        <Testimonials />
        {/* <h2>Testimonials</h2>
        <div className="underline-1"></div>
        <div className="testimony">
          <p>
            <i className="fs-6">
              "I really give God the glory for everything works together for
              good. This academy was introduced to me by miss Amarachi Chris. At
              first I didn't think it'll be something like this but it turned
              out this way. Indeed it was the plan of God for me to be a part of
              this academy. Actually, as a student, I have no source of income,
              and when i was instructed to get the manual I was thinking of how
              to do that, but God provided miraculously."
            </i>
          </p>
          <Link
            to="/about"
            style={{ textDecoration: "none" }}
            className="btn-1 button-bg text-white"
          >
            <strong>See more</strong>
          </Link>
        </div> */}
      </section>

      {/* Donate/Give Section */}
      {/* <section className="container">
        <Donate />
      </section> */}

      {/* <section className="section-5">
      <div className="container">
        <div className="row form">
          <div className="col-md-5 form-update">
            <h1>Get Fresh Updates</h1>
            <p>Get weekly updates, link to every posts and events from us. Bottom line is,
              you don't want to miss this.
              Sign up here.
            </p>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <form className="sub-button">
              <input type="text" className="form-control input-name" placeholder="Name"></input>
              <input type="email" className="form-control input-email" placeholder="Email Address"></input>
              <div><button className="border pt-1 border-0 input-button fs-4">Subscribe!</button></div>
            </form>
          </div>
        </div>
      </div>
    </section> */}
      <Footer />
    </div>
  );
}

export default Home;
